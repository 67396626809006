import { subDays } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../app/appSlice";
import { optionTypeAsync } from "../app/patientSlice";
import {
  getTretmentFaciltiestCustomerAsyn,
  setTreatmentCustomerFacilities,
} from "../app/usageAnalyticsReportSlice";
import {
  getTreatmentOutcomeOverViewData,
  setTreatmentReportPayload,
  setTreatmentWorklistData,
  setTreatmentOverViewData,
} from "../app/woundReportSlice";
import { TreatmentReportData } from "../components/core/TreatmentReport/treatmentReportData";
import {
  Button,
  CheckBox,
  DateTimePicker,
  Form,
  MultiSelect,
  Organizational,
  SelectBox,
  TextField,
} from "../components/primary";
import { Header } from "../components/secondary/Header";
import Spreadsheet from "../static/images/ExportSpredsheet.svg";
import rightArrow from "../static/images/right_arrow.svg";
import { convertnewDateToDate, getFacilityLabel } from "../utils";
import style from "./treatmentReport.module.scss";

export const TreatmentReport = ({ history }) => {//NOSONAR
  const [firstTime, setFirstTime] = useState("");
  //const [tempPayload, setTempPayload] = useState("");
  const dispatch = useDispatch();
  const treatmentReportPayload = useSelector(
    (state) => state.woundreport.treatmentReportPayload
  );
  const userDetails = useSelector((state) => state.user);
  useEffect(() => {
    if (userDetails?.role !== "MedlineAdministrator"&&userDetails?.customerSettings?.ne1Wms === 0) {  
      history.push('/learnMore'); 
    }
    if (
      userDetails &&
      userDetails.customerSettings &&
      userDetails?.customerSettings?.ne1Wms === 0
    ) {
      if (
        userDetails?.role === "ExternalStandardUser" ||
        userDetails?.role === "ExternalAdministrator" ||
        userDetails.role === "ExternalNE1WoundExpert" ||
        userDetails.role === "ExternalNE1Review" ||
        userDetails.role === "ExternalNE1Assesment"
      ) {
        history.push("/learnMore");
      }
    }
  }, [userDetails]);
  useEffect(() => {
    dispatch(setTreatmentWorklistData({}));
    dispatch(setTreatmentOverViewData({}));
    dispatch(setTreatmentCustomerFacilities({}));
    dispatch(getTretmentFaciltiestCustomerAsyn(treatmentReportPayload.tempTreatmentCustomer?treatmentReportPayload.tempTreatmentCustomer:userDetails?.customerNumber));
    setFirstTime(true);
    setShowApplyState(false);
    dispatch(setTreatmentReportPayload({}));
    let wountCountPayload = {
      ...treatmentReportPayload,
      optionSelected: treatmentReportPayload?.optionSelected?treatmentReportPayload.optionSelected:"Wound Cleansers",
    };
     dispatch(setTreatmentReportPayload(wountCountPayload));
  }, []);

  /* Use hook form */
  const {
    register,
    handleSubmit,
    control,
    errors,
    reset,
    setError,
    watch,
  } = useForm();

  let dateRangeRef1 = useRef();
  let dateRangeRef2 = useRef();

  const [startDate, setStartDate] = useState(new Date());
  const [toDateRes, setToDateRes] = useState("");
  const [showApplyState, setShowApplyState] = useState(false);
  const [onselectOrgz, setOnselectOrgz] = useState([]);
  const [selectedDivOption, setSelectedDivOption] = useState(
    treatmentReportPayload && treatmentReportPayload.optionSelected
      ? treatmentReportPayload.optionSelected
      : "Wound Cleansers"
  );

  const [errorMsg, setErrorMsg] = useState("");
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedWoundValue, setSelectedWoundValue] = useState(treatmentReportPayload?.woundType
    ? treatmentReportPayload.woundType
    : []);

  const [resetFacility, setResetFacility] = useState(false);
  const [submitState, setSubmitState] = useState(false);
  const [adminAccess, setAdminAccess] = useState(false);
  const [orgError, setOrgError] = useState(false);

  useEffect(() => {
    if (
      (userDetails && userDetails.role === "MedlineAdministrator") ||
      userDetails.role === "MedlineSupport"
    ) {
      setAdminAccess(true);
    }
  }, [userDetails]);

  const woundTypeRef = useRef();

  const customFromDatePickerChange = (e) => {
    const fromDates = new Date(e).getTime();
    const toDates = new Date(
      document.getElementById("datePicker2").value
    ).getTime();
    setToDateRes(new Date(e));
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
      }
    }, 500);
    setShowApplyState(true);
  };
  const handleOnBlurFrom = ({ target: { value } }) => {
    const fromDates = new Date(value).getTime();
    const toDates = new Date(
      document.getElementById("datePicker2").value
    ).getTime();
    setToDateRes(new Date(value));
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
      }
    }, 500);
  };
  const handleOnBlurTO = ({ target: { value } }) => {
    const fromDates = new Date(
      document.getElementById("datePicker1").value
    ).getTime();
    const toDates = new Date(value).getTime();
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
        setToDateRes("");
      }
    }, 500);
  };
  const customToDatePickerChange = (e) => {
    setShowApplyState(true);
    const fromDates = new Date(
      document.getElementById("datePicker1").value
    ).getTime();
    const toDates = new Date(e).getTime();
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker1").value = "";
        setToDateRes("");
      }
    }, 500);
  };

  const getCustomerNumber = (value) => {
    if (value === "") {
      setErrorMsg("Please enter primary group code");
    } else {
      // API to check wheather customer exists or not
      setShowApplyState(true);
      setOnselectOrgz([]);
      dispatch(getTretmentFaciltiestCustomerAsyn(value));
      dispatch(setTreatmentReportPayload({...treatmentReportPayload,onselectOrgz:[]}))
    }
  };

  const getFacilities = useSelector(
    (state) => state.usageAnalyticsReport.treatmentCustomerFacilities
  );

  let optionData = [];
  const soldToFacilities = getFacilities?.data?.facilities?.filter(
    (f) => f.accountGroup === "0001"
  );
  soldToFacilities &&
    soldToFacilities.forEach((soldToFacility) => {
      const shipToFacilities = getFacilities.data.facilities.filter(
        (f) =>
          f.accountGroup === "0002" &&
          f.soldToNumber === soldToFacility.facilityNumber
      );
      if (shipToFacilities.length === 0) {
        optionData.push({
          cat: getFacilityLabel(soldToFacility),
          key: getFacilityLabel(soldToFacility),
          facilityNumber: soldToFacility.facilityNumber,
          soldToFacility: "soldToFacility",
        });
      }
      shipToFacilities &&
        shipToFacilities.forEach((shipToFacility) => {
          optionData.push({
            cat: getFacilityLabel(soldToFacility),
            key: getFacilityLabel(shipToFacility),
            facilityNumber: shipToFacility.facilityNumber,
            shipToFacilities: "shipToFacilities",
          });
        });
    });

  useEffect(() => {
    let fac = "";
    if (getFacilities?.errorMessage) {
      fac = "Primary group code doesn’t match our record";
    }
    setErrorMsg(fac);
  }, [getFacilities]);

  const onSelectOrg = (selectedList) => {
    if (
      Array.isArray(optionData) &&
      selectedList &&
      Array.isArray(selectedList)&&
      optionData?.length !== selectedList?.length
    ) {
      setShowApplyState(true);
      setResetFacility(false);
    }
    if (setSelectedFacilities) {
      setOrgError(false);
      let facilitykey = [];
      selectedList &&
        Array.isArray(selectedList)&&selectedList.map((item) => {//NOSONAR
          let facility = item.key.split("|")[0].trim();
          facilitykey = [...facilitykey, facility];
        })//NOSONAR
      setSelectedFacilities(facilitykey);
    }
    if(Array.isArray(selectedList)&&selectedList.length===0){
      setOnselectOrgz([])
    }else{
    setOnselectOrgz([...selectedList])
    }
  };

  useEffect(() => {
    dispatch(optionTypeAsync("insurancepayer"));
    dispatch(optionTypeAsync("woundType"));
  }, []);

  const insurancePayerData = useSelector(
    (state) => state.patient.insurancePayer
  );
  const woundTypeData = useSelector((state) => state.patient.woundType);

  const handleWoundChange = (e) => {
    setShowApplyState(true);
    if (e && e?.length > 0) {
      let arr = [];
      e.map((data) => {
        if (data.key !== "") {
          arr.push(data.key);
        }
      });
      setSelectedWoundValue(arr);
    }
  };

  const handlePayorChange = (e) => {
    setShowApplyState(true);
  };
  const onClickCheckBox = (e) => {
    setShowApplyState(true);
  };

  // reset fields.
  const resetData = () => {
    setOnselectOrgz([])
    dispatch(setTreatmentCustomerFacilities({}));
    dispatch(setTreatmentReportPayload({}));
    reset({ woundType: "", payorType: "", isDischargedPatient: "" });
    woundTypeRef.current.resetSelectedValues([]);
    setSelectedWoundValue("");
    setShowApplyState(true);
    setSubmitState(false);
    setResetFacility(true);
    setSelectedDivOption("Wound Cleansers");
    dispatch(getTretmentFaciltiestCustomerAsyn(userDetails?.customerNumber));
    setTimeout(() => {
      // pageOnloadAPI();
      setFirstTime(true);
    }, 500);
  };

  const optionSelected = (e) => {
    setSelectedDivOption(e);
    /*let wountCountPayload = { ...treatmentReportPayload, optionSelected: e }
        dispatch(setTreatmentReportPayload(wountCountPayload))*/
    let reportPayloadPayload = { ...treatmentReportPayload, optionSelected: e }
    dispatch(setTreatmentReportPayload({ ...reportPayloadPayload, optionSelected: e }))
    let wountCountPayload = { ...treatmentReportPayload, optionSelected: e };
    setTreatmentReportPayload(wountCountPayload);
  };

  const pageOnloadAPI = () => {
    let facilitykey = [];
    optionData &&
      Array.isArray(optionData) &&
      optionData.map((item) => {//NOSONAR
        let facility = item.key.split("|")[0].trim();
        facilitykey = [...facilitykey, facility];
      })//NOSONAR
    const payload = {
      fromDate: convertnewDateToDate(
        new Date(document.getElementById("datePicker1").value)
      ),
      toDate: convertnewDateToDate(
        new Date(document.getElementById("datePicker2").value)
      ),
      facility: facilitykey,
      woundType: "",
      payor: "",
      isDischargedPatient: 0,
    };
    if (optionData.length > 0) {
      if (
        treatmentReportPayload &&
        treatmentReportPayload?.facility &&
        treatmentReportPayload?.facility.length !== 0
      ) {
        dispatch(getTreatmentOutcomeOverViewData(treatmentReportPayload));
        setTreatmentReportPayload(treatmentReportPayload);
        setShowApplyState(true);
      } else {
        if (payload.facility && payload.facility.length > 0) {
          let treatPayload = { ...payload, optionSelected : treatmentReportPayload?.optionSelected?treatmentReportPayload.optionSelected:"Wound Cleansers",onselectOrgz:optionData }
               
          dispatch(getTreatmentOutcomeOverViewData(treatPayload));
          setTreatmentReportPayload(treatPayload);
          dispatch(setTreatmentReportPayload(treatPayload));
        }
      }

      if (document.getElementById("datePicker1").value) {
        setToDateRes(new Date(document.getElementById("datePicker1").value));
      }
    }
  };

  useEffect(() => {
    if (
      firstTime &&
      getFacilities?.data?.facilities.length > 0 &&
      document.getElementById("datePicker1") &&
      document.getElementById("datePicker2")
    ) {
      setTimeout(() => {
        setFirstTime(false);
        pageOnloadAPI();
      }, 100);
    }
  }, [firstTime, getFacilities]);

  const overViewData = useSelector(
    (state) => state.woundreport.treatmentOverViewData
  );

  const onSubmit = (data) => {
    setErrorMsg("");
    if (selectedFacilities.length === 0) {
      setOrgError(true);
    } else {
      setOrgError(false);
    }
    setSubmitState(true);
    let payload = {
      fromDate: convertnewDateToDate(data.datePicker1),
      toDate: convertnewDateToDate(data.datePicker2),
      facility: selectedFacilities,
      woundType: selectedWoundValue,
      payor: data.payorType,
      isDischargedPatient:
        data.isDischargedPatient === "Include Discharged Patients" ? 1 : 0,
    };
    const fromDates = new Date(
      document.getElementById("datePicker1").value
    ).getTime();
    const toDates = new Date(
      document.getElementById("datePicker2").value
    ).getTime();
    setToDateRes(new Date(document.getElementById("datePicker1").value));
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
      } else {
        if (selectedFacilities.length >0) {
        dispatch(getTreatmentOutcomeOverViewData(payload));
        setTreatmentReportPayload({ ...payload, selectedOption: "Wound Cleansers" });
        setSelectedDivOption("Wound Cleansers");
        let tempTreatmentPayload = {...payload,onselectOrgz:onselectOrgz};
        if (document.getElementById("customerNumber").value) {
          tempTreatmentPayload = {
            ...tempTreatmentPayload,
            tempTreatmentCustomer: document.getElementById("customerNumber")
              .value,
          };
        }
        dispatch(setTreatmentReportPayload(tempTreatmentPayload))
      }
    }
    }, 10);
  };

  const treatmentPdfPage = () => {
    dispatch(setLoading(true));
    history.push("/treatmentReport/pdfdownload");
  };
  return (
    <>
      <div className="right_block">
        <Header reportHeading={"Treatment Outcomes"} />
        <div className={`col ${style.wound_dashboard} mt-0`}>
          <Form className={style.wound_form} onSubmit={handleSubmit(onSubmit)}>
            <div className={`col-8 ${style.col_spec}`}>
              <label className={`col-5 ${style.label_spec}`}>Date Range:</label>
              <DateTimePicker
                id={"datePicker1"}
                name={"datePicker1"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 ${style.col_spec}`,
                }}
                customOnChange={customFromDatePickerChange}
                defaultValue={
                  treatmentReportPayload && treatmentReportPayload.fromDate
                    ? new Date(treatmentReportPayload.fromDate)
                    : subDays(new Date(startDate), 30)
                }
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef1}
                errors={errors}
                maxDate={new Date()}
                onBlur={handleOnBlurFrom}
              />
              <DateTimePicker
                id={"datePicker2"}
                name={"datePicker2"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 pr-0 ${style.col_spec}`,
                }}
                defaultValue={
                  treatmentReportPayload && treatmentReportPayload.toDate
                    ? new Date(treatmentReportPayload.toDate)
                    : new Date()
                }
                customOnChange={customToDatePickerChange}
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef2}
                minDate={toDateRes ? toDateRes : ""}
                errors={errors}
                maxDate={new Date()}
                onBlur={handleOnBlurTO}
              />
            </div>
            <div className={`col-4 ${style.col_spec}`}></div>
            {adminAccess && (
              <>
                <TextField
                  id={"customerNumber"}
                  name={"customerNumber"}
                  label={"Primary Group Code:"}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    labelClass: `col-5 col-form-label ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                    inputClassName: `${style.wound_input}  customerNum`,
                  }}
                  type="text"
                  errors={errors}
                  validator={register}
                  customError={errorMsg ? errorMsg : ""}
                  defaultValue={
                    treatmentReportPayload &&
                    treatmentReportPayload.tempTreatmentCustomer
                      ? treatmentReportPayload.tempTreatmentCustomer
                      : ""
                  }
                  required={true}
                />
                <span
                  className={`col-3 col-form-label ${style.form_span} getorg `}
                  onClick={(e) =>
                    getCustomerNumber(
                      document.getElementById("customerNumber").value
                    )
                  }
                >
                  Search{" "}
                  <img
                    src={rightArrow}
                    alt=""
                    className={`${style.img_left}`}
                  />
                </span>
              </>
            )}

            {optionData &&
              Array.isArray(optionData) &&
              optionData.length === 0 && (
                <Organizational
                  id={"facility"}
                  name={"facility"}
                  label={`Facility/Location:`}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    inputClassName: style.wound_select,
                    labelClassName: `col-5 ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                  }}
                  optionValue={Array.isArray(optionData)?optionData:[]}
                  groupBy="cat"
                  displayValue="key"
                  onSelect={onSelectOrg}
                  selectAll={true}
                  resetDefault={resetFacility}
                  reportView={true}
                  customError={orgError}
                  required={true}
                  defaultValue={Array.isArray(onselectOrgz)&&onselectOrgz.length>0?onselectOrgz:optionData}
                />
              )}

            {optionData &&
              Array.isArray(optionData) &&
              optionData.length !== 0 && (
                <Organizational
                  id={"facility"}
                  name={"facility"}
                  label={`Facility/Location:`}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    inputClassName: style.wound_select,
                    labelClassName: `col-5 ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                  }}
                  optionValue={optionData}
                  groupBy="cat"
                  displayValue="key" 
                  defaultValue={Array.isArray(treatmentReportPayload?.onselectOrgz)&& treatmentReportPayload.onselectOrgz.length>0
                    ? treatmentReportPayload.onselectOrgz:Array.isArray(onselectOrgz)&&onselectOrgz.length>0?onselectOrgz:optionData}
                  onSelect={onSelectOrg}
                  selectAll={true}
                  resetDefault={resetFacility}
                  reportView={true}
                  customError={orgError}
                  required={true}
                />
              )}

            <div className={`col-4 ${style.col_spec}`}></div>

            <MultiSelect
              id={"woundType"}
              name={"woundType"}
              label={"Wound Types:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0 ${style.select_wrapper}`,
              }}
              optionValue={woundTypeData}
              validator={(e) => {
                register({ required: true });
                woundTypeRef.current = e;
              }}
              placeholder="Select"
              showCheckbox={true}
              displayValue="value"
              onSelect={handleWoundChange}
              onRemove={handleWoundChange}
              labelSubText={"Select all that apply"}
              defaultValue={
                treatmentReportPayload && treatmentReportPayload.woundType
                  ? treatmentReportPayload.woundType
                  : []
              }
            />
            <div className={`col-4 ${style.col_spec}`}></div>
            <SelectBox
              id={"payorType"}
              name={"payorType"}
              label={"Payor Type:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0 ${style.select_payortype}`,
              }}
              optionValue={insurancePayerData}
              validator={register}
              placeholder="Select"
              errors={errors}
              onChange={(e) => handlePayorChange(e.currentTarget.value)}
              defaultValue={
                treatmentReportPayload && treatmentReportPayload.payor
                  ? treatmentReportPayload.payor
                  : ""
              }
            />

            <div className={`col-4 ${style.col_spec}`}></div>

            <div className={`col-8 ${style.col_spec} p-0`}>
              <div className="col-6 offset-5 p-0 mb-0">
                <CheckBox
                  classes={{ labelClassName: style.custom_control_label }}
                  label={"Include Discharged Patients"}
                  type={"checkbox"}
                  name={"isDischargedPatient"}
                  id={"checkBoxId"}
                  validator={register}
                  onClick={(e) => onClickCheckBox(e.currentTarget.value)}
                  woundReport={true}
                  defaultChecked={
                    treatmentReportPayload &&
                    treatmentReportPayload.isDischargedPatient === 1
                      ? true
                      : ""
                  }
                />
              </div>
            </div>
            <div className={`col-4 ${style.col_spec}`}></div>
            {showApplyState && (
              <div className={`col-8 ${style.col_spec}`}>
                <label className={`col-5 ${style.label_spec}`}></label>
                <Button
                  classes={{
                    className: `btn ${style.btn_custom} ${style.btn_small} mr-4`,
                  }}
                  type="submit"
                >
                  Apply
                </Button>
                <Button
                  classes={{
                    className: `btn ${style.btn_custom} ${style.btn_small_cancel} `,
                  }}
                  onClick={resetData}
                  type={"button"}
                >
                  Reset
                </Button>
              </div>
            )}
          </Form>

          {/* {(overViewData != "") && (woundAssessmentData != "") ? */}
          <>
            <div className={style.overview_blk}>
              <div className={`${style.overview_top} justify-content-end`}>
                <span className={style.down_spread} onClick={treatmentPdfPage}>
                  <img src={Spreadsheet} alt="" />
                  <label> Download as PDF </label>
                </span>
              </div>
              <div className={style.overview_score}>
                <div
                  className={`crd_opt_1 ${style.overview_percentage} ${
                    selectedDivOption === "Wound Cleansers" ? style.active : ""
                  }`}
                  onClick={() => optionSelected("Wound Cleansers")}
                >
                  <label className={style.dash_top}>Wound Cleansers</label>
                  <p className={style.dash_btm}>
                    <span>{overViewData?.woundCleansers?.count}</span>
                    <span>{overViewData?.woundCleansers?.percent}</span>
                  </p>
                </div>
                <div
                  className={`crd_opt_2 ${style.overview_percentage} ${
                    selectedDivOption === "Skin Protectants" ? style.active : ""
                  }`}
                  onClick={() => optionSelected("Skin Protectants")}
                >
                  <label className={style.dash_top}>Skin Protectants</label>
                  <p className={style.dash_btm}>
                    <span>{overViewData?.skinProtectants?.count}</span>
                    <span>{overViewData?.skinProtectants?.percent}</span>
                  </p>
                </div>
                <div
                  className={`crd_opt_3 ${style.overview_percentage} ${
                    selectedDivOption === "Primary Dressings"
                      ? style.active
                      : ""
                  }`}
                  onClick={() => optionSelected("Primary Dressings")}
                >
                  <label className={style.dash_top}>Primary Dressings </label>
                  <p className={style.dash_btm}>
                    <span>{overViewData?.primaryDressings?.count}</span>
                    <span>{overViewData?.primaryDressings?.percent}</span>
                  </p>
                </div>
                <div
                  className={`crd_opt_4 ${style.overview_percentage} ${
                    selectedDivOption === "Secondary Dressings"
                      ? style.active
                      : ""
                  }`}
                  onClick={() => optionSelected("Secondary Dressings")}
                >
                  <label className={style.dash_top}>Secondary Dressings </label>
                  <p className={style.dash_btm}>
                    <span>{overViewData?.secondaryDressings?.count}</span>
                    <span>{overViewData?.secondaryDressings?.percent}</span>
                  </p>
                </div>
                <div
                  className={`crd_opt_5 ${style.overview_percentage} ${
                    selectedDivOption === "Compression" ? style.active : ""
                  }`}
                  onClick={() => optionSelected("Compression")}
                >
                  <label className={style.dash_top}>Compression </label>
                  <p className={style.dash_btm}>
                    <span>{overViewData?.compression?.count}</span>
                    <span>{overViewData?.compression?.percent}</span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <TreatmentReportData
                tempPayload={treatmentReportPayload}
                selectedDivOption={selectedDivOption}
              />
              ,
            </div>
          </>

          {/* <div className={style.no_matching_result}>Oops there are no matching results, please update your filter</div> */}

          {/* } */}
        </div>
      </div>
    </>
  );
}//NOSONAR
