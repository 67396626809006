import { LIST_VIEW_LIMIT } from "../constants";

export const customFromDatePickerChangeMove = (data, setFromDate, setFromDateError, setToDateError, setShowApplyButton) => {
    setFromDate(data)
    const toy = new Date(document.getElementById('toDate').value)
    let outDate = data
    outDate.setHours(0,0,0,0);
    if (toy < data) {
      setFromDateError("From date should be less than or equal to 'To date'")
      setToDateError(null)
    //  clearErrors(['toDate'])
      setShowApplyButton(false);
    }
    if (toy > data) {
      setFromDateError(null)
      setToDateError(null)
      setShowApplyButton(true);
    }
    if(toy.toString() === outDate.toString()){
      setFromDateError(null)
      setToDateError(null)
      setShowApplyButton(true);
    }
}

export const customToDatePickerChangeMove = (data, setToDate, setFromDateError, setToDateError, setShowApplyButton) => {
    setToDate(data)
    const fromy = new Date(document.getElementById('fromDate').value)
    if (data < fromy) {
      setToDateError("To date should be greater than or equal to 'From date'")
      setFromDateError(null)
     // clearErrors(['fromDate'])
      setShowApplyButton(false);
    }
    if (data > fromy) {
      setFromDateError(null)
      setToDateError(null)
      setShowApplyButton(true);
    }
}

// export const disableChangeMove = (setShowApplyButton, customerNumber, setDisableCustomerGroup, setCustomerDefault) => {
//     // setShowApplyButton(true);
//     // if(customerNumber !== ''){
//     //   setCustomerDefault([])
//     //   setDisableCustomerGroup(true)
//     // }else{
//     //   setDisableCustomerGroup(false)
//     // }
// }

export const handleCustomerNumberMove = (setSurveyNameData, setError, dispatch, checkCustomer, setSurveyDefault, setCustomerDefault, setShowApplyButton, setDisableCustomerGroup) => {
    setSurveyNameData([])
    setSurveyDefault([])
    setCustomerDefault([])
    const data = document.getElementById('customerNumber').value
    if (data === '') {
      setError('customerNumber', {
        type: "manual",
        message: "Please enter customer number"
      })
    } else {
      // setSurveyDefault([])
      // setSurveyNameData([]);
      // API to check wheather customer exists or not
      setShowApplyButton(true);
      setDisableCustomerGroup(true)
      dispatch(checkCustomer(data))
    }
}

// export const handleSelectMove = (e, surveyRef, setSurveyNameData, setShowApplyButton, setCustomerReset, dispatch, getSurveyListOptions, setCustomerGroupData, setSurveyReset) => {
//     const selectedData = e;
//     surveyRef.current.resetSelectedValues();
//     setSurveyNameData([])
//     setSurveyReset([])
//     setShowApplyButton(true)
//     if (selectedData && selectedData?.length > 0) {
//       let arr = [];
//       selectedData.map((data) => {
//         // If selected value is not 'all', check if we already selected 'all', if yes, remove that and add new item
//         // If there is no 'all' selected already, just add newly selected item
//         if (data.key !== 'all') {
//           if (arr.indexOf('all') === -1) {
//             arr.push(data.key)
//             setCustomerReset([...arr])
//           }
//           else {
//             arr.splice(arr.indexOf('all'), 1)
//             arr.push(data.key)
//             setCustomerReset([...arr])
//           }
//         }
//         // Below condition is for selecting 'all'
//         if (data.key === 'all') {
//           arr = [];
//           arr.push(data.key)
//           setCustomerReset(['all'])
//         }
//       })
//       // Calling API everytime we make changes in customer group
//       const payload = {
//         customerName: null,
//         customerGroup: [...arr]
//       }
//       dispatch(getSurveyListOptions(payload))
//       setCustomerGroupData(arr)
//     }
//     else {
//       const payload = {
//         customerName: null,
//         customerGroup: ['all']
//       }
//       dispatch(getSurveyListOptions(payload))
//       setCustomerGroupData([])
//     }
// }

// export const surveyNameSelectMove = (e, clearErrors, setShowApplyButton, setSurveyReset, setSurveyNameData) => {
//     if (e.length > 0) {
//       clearErrors(['surveyName'])
//     }
//     setShowApplyButton(true)
//     if (e && e?.length > 0) {
//       let arr = [];
//       e.map((data) => {
//         if (data.key !== 'all') {
//           if (arr.indexOf('all') === -1) {
//             arr.push(data.key)
//             setSurveyReset([...arr])
//           }
//           else {
//             arr.splice(arr.indexOf('all'), 1)
//             arr.push(data.key)
//             setSurveyReset([...arr])
//           }
//         }
//         // Below condition is for selecting 'all'
//         if (data.key === 'all') {
//           arr = [];
//           arr.push(data.key)
//           setSurveyReset(['all'])
//         }
//       })
//       setSurveyNameData([...arr])
//     }
//     else {
//       setSurveyNameData([])
//     }
// }

export const resetDataMove = (reset, subDays, setSurveyNameData, setDisableCustomerGroup, setShowApplyButton, setFirstTime, getSurveyList, setSurveyDefault, setCustomerDefault, setFromDateError, setToDateError, clearErrors, setCsvData, setNumberOfQA, setCsvHeader, setResetStart, setFromDate, setToDate) => {
    setResetStart(true)
    setFirstTime(true)
    reset({ fromDate: subDays(new Date(), 30), toDate: new Date() });
    setFromDate(subDays(new Date(), 30))
    setToDate(new Date())
    getSurveyList(null, ['surveyassignedtospecificcustomerno', 'all', 'acute', 'postacute'])
    // setCustomerGroupData(['all'])
    setSurveyNameData([])
    setDisableCustomerGroup(false)
    setShowApplyButton(false);
    setSurveyDefault(['all'])
    setCustomerDefault(['all'])
    setFromDateError(null)
    setToDateError(null)
    setCsvData(null)
    setNumberOfQA(0)
    setCsvHeader([
      { label: 'First Name', key: 'firstName' },
      { label: 'Last Name', key: 'lastName' },
      { label: 'Role', key: 'role' },
      { label: 'Phone No', key: 'phoneNo' },
      { label: 'Email Id', key: 'emailId' },
      { label: 'Survey Name', key: 'surveyName' },
      { label: 'Survey Published Date', key: 'surveypublishedDate' },
      { label: 'Submitted On', key: 'submittedOn' },
      { label: 'Customer Number', key: 'customerNumber' },
      { label: 'Customer Name', key: 'customerName' },
    ])
    clearErrors()
  }

  export const getActivePageMove = (data, setActivePage, setSurveyResults, sortType, format, fromDate, toDate, surveyNameData, dispatch, getSurveyResultsAlt) => {
    setActivePage(data);
    const paginationPayLoad = {};
    paginationPayLoad['url'] = '/reports/survey'
    paginationPayLoad['method'] = 'POST'
    paginationPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT }
    paginationPayLoad['setMethod'] = setSurveyResults
    paginationPayLoad['sortType'] = sortType
    paginationPayLoad['toBeSent'] = {
      dateFrom: format(fromDate, "LL/dd/yyyy"),
      dateTo: format(toDate, "LL/dd/yyyy"),
      surveyId: surveyNameData
    }
    dispatch(getSurveyResultsAlt(paginationPayLoad));
  }