import React, { useRef, useEffect, useState } from "react";
import style from "./bIReports.module.scss";
import {
  Form,
  TextField,
  Button,
  SelectBox,
  DateTimePicker,
  MultiSelect,
  CheckBox,
} from "../components/primary";
import { useForm } from "react-hook-form";
import { HPR_Worst_Tissue_Type, CANCEL_BTN } from "../constants";
import {
  checkNumbericValue,
  getFacilityLabel,
  checkPercentageNumbericValue,
  convertnewDateToDate,
} from "../utils/commonHelper";
import circleArrow from "../static/images/circle_arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCustomerAsync,
  optionTypeAsync,
  getClinicianandproductcategory,
  getTissueTypeandClassification,
  postDashboardAsync,
  setClinicianandproductcategory,
  setTissuetypeandClassification,
  postDetailCsvBiReport,
  getTreatMentBiList,
  setTreatMentBiListOption
} from "../app/patientSlice";
import { addDays, format, subDays } from "date-fns";
import { Header } from "../components/secondary/Header";
import { Organizational } from "../components/primary";
import { LIST_VIEW_LIMIT } from "../constants";
import rightArrow from "../static/images/right_arrow.svg";
import { getFaciltiestCustomerAsyn } from "../app/usageAnalyticsReportSlice";

export const BIReports = ({ history }) => {//NOSONAR
  const dispatch = useDispatch();
  const listHumanAnatomy = useSelector((state) => state.patient.humanAnatomy);
  const listphotoType = useSelector((state) => state.patient.photoType);
  const listwoundAge = useSelector((state) => state.patient.woundAge);
  const listwoundProgress = useSelector((state) => state.patient.woundProgress);
  const listdressingappearanceListoptions = useSelector(
    (state) => state.patient.dressingappearanceListoptions
  );
  const listadditionalCareListoptions = useSelector(
    (state) => state.patient.additionalCareListoptions
  );
  const listexudateType = useSelector((state) => state.patient.exudateType);
  const listmodalitiesListoptions = useSelector(
    (state) => state.patient.modalitiesListoptions
  );
  const listexudateAmount = useSelector((state) => state.patient.exudateAmount);
  const listhistoryCause = useSelector((state) => state.patient.historyCause);
  const listdebridement = useSelector(
    (state) => state.patient.surgicaldebridementprocedure
  );
  const listClinician = useSelector((state) => state.patient.clinician);
  const listWoundType = useSelector((state) => state.patient.woundType);
  const clinicianandproductcategoryOld = useSelector(
    (state) => state.patient.clinicianandproductcategory
  );
  const clinicianandproductcategory = useSelector(
    (state) => state.patient.treatMentBiListOption
  );
  const listtissuetypeandClassification = useSelector(
    (state) => state.patient.tissuetypeandClassification
  );
  const userRole = useSelector((state) => state.user);
  useEffect(() => {
    if (
      userRole &&
      userRole.customerSettings &&
      userRole?.customerSettings?.ne1Wms === 0
    ) {
      if (
        userRole?.role === "ExternalStandardUser" ||
        userRole?.role === "ExternalAdministrator" ||
        userRole.role === "ExternalNE1WoundExpert" ||
        userRole.role === "ExternalNE1Review" ||
        userRole.role === "ExternalNE1Assesment"
      ) {
        history.push("/learnMore");
      }
    }
  }, [userRole]);
  const { register, handleSubmit, control, errors, reset, watch } = useForm({
    mode: "onBlur",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [getvalue, setValue] = useState({});
  const [empty, setEmpty] = useState([]);
  const [productManagerData, setProductManagerData] = useState();
  const [woundTypeData, setwoundTypeData] = useState();
  const [tissueTypesIdDisable, setTissueTypesIdDisable] = useState(false);
  const [resetData, setResetDate] = useState([]);
  const [showApplyState, setShowApplyState] = useState(false);
  const [pi, setPi] = useState("");
  const [selectedCause, setSelectedCause] = useState("");
  const [cliniacal_productcategory, setCliniacal_productcategory] = useState();
  const [resetFacility, setResetFacility] = useState(false);
  const [orgError, setOrgError] = useState(false);
  const [adminAccess, setAdminAccess] = useState(false);

  useEffect(() => {
    if (
      (userRole && userRole.role === "MedlineAdministrator") ||
      userRole.role === "MedlineSupport"
    ) {
      setAdminAccess(true);
    }
  }, [userRole]);

  /* Handle multiselect dropdown */
  const productMangerRef = useRef();
  const facilityDropRef = useRef();
  const clinicianIdRef = useRef();
  const assessmentTypeIdRef = useRef();
  const photoTypeIdRef = useRef();
  const woundTypesIdRef = useRef();
  const woundProgressIdRef = useRef();
  const woundClassificationIdRef = useRef();
  const woundAgeIdRef = useRef();
  const bodyAnatomyIdRef = useRef();
  const tissueTypesIdRef = useRef();
  const infectedIdRef = useRef();
  const openAreaIdRef = useRef();
  const yellowFibrinIdRef = useRef();
  const exudatedTypesIdRef = useRef();
  const biofilimIdRef = useRef();
  const exudatedAmountRef = useRef();
  const dressingApperanceRef = useRef();
  const debrimentRef = useRef();
  const modalitiesRef = useRef();
  const additionalCareRef = useRef();
  const primaryDressingRef = useRef();
  const secondaryDressingRef = useRef();
  const compresssionRef = useRef();
  const woundCleanserRef = useRef();
  const skinProtectantRef = useRef();

  let dateRangeRef1 = useRef();
  let dateRangeRef2 = useRef();

  const customFromDatePickerChange = () => {
    setShowApplyState(true);
  };

  const customToDatePickerChange = () => {
    setShowApplyState(true);
  };

  const fromDateWatch = convertnewDateToDate(watch("datePicker1"));
  const toDateWatch = convertnewDateToDate(watch("datePicker2"));

  const wountTypeWatch = watch("");

  const booleanOptions = [
    { key: "yes", value: "Yes" },
    { key: "no", value: "No" },
  ];
  const assessmentTypeData = [
    { key: "initialassessment", value: "Initial assessment" },
    { key: "re-eval/followup", value: "Re-Eval / Follow up " },
  ];

  useEffect(() => {
    dispatch(getFaciltiestCustomerAsyn(userRole?.customerNumber));
    dispatch(optionTypeAsync("humananatomy"));
    dispatch(optionTypeAsync("phototype"));
    dispatch(optionTypeAsync("woundage"));
    dispatch(optionTypeAsync("woundprogress"));
    dispatch(optionTypeAsync("dressingappearance"));
    dispatch(optionTypeAsync("additionalcare"));
    dispatch(optionTypeAsync("modalities"));
    dispatch(optionTypeAsync("exudatetype"));
    dispatch(optionTypeAsync("exudateamount"));
    dispatch(optionTypeAsync("surgicaldebridementprocedure"));
    dispatch(optionTypeAsync("historycause"));
    dispatch(optionTypeAsync("woundType"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setTreatMentBiListOption({}));
    dispatch(setClinicianandproductcategory({}));
  }, [dispatch]);

  const facilitiesData = useSelector(
    (state) => state.usageAnalyticsReport.customerFacilities
  );

  const [toDateRes, setToDateRes] = useState("");

  let optionData = [];
  const soldToFacilities = facilitiesData?.data?.facilities?.filter(
    (f) => f.accountGroup === "0001"
  );

  soldToFacilities &&
    soldToFacilities.forEach((soldToFacility) => {
      const shipToFacilities = facilitiesData.data.facilities.filter(
        (f) =>
          f.accountGroup === "0002" &&
          f.soldToNumber === soldToFacility.facilityNumber
      );
      if (shipToFacilities.length === 0) {
        optionData.push({
          cat: getFacilityLabel(soldToFacility),
          key: getFacilityLabel(soldToFacility),
          facilityNumber: soldToFacility.facilityNumber,
          soldToFacility: "soldToFacility",
        });
      }
      shipToFacilities &&
        shipToFacilities.forEach((shipToFacility) => {
          optionData.push({
            cat: getFacilityLabel(soldToFacility),
            key: getFacilityLabel(shipToFacility),
            facilityNumber: shipToFacility.facilityNumber,
            shipToFacilities: "shipToFacilities",
          });
        });
    });

  useEffect(() => {
        let fac = ""
        if (facilitiesData?.errorMessage === "Invalid Customer Number.") {
            fac = "primary group code doesn’t match our record"
        } else if (facilitiesData?.data !== undefined) {
            if (optionData.length === 0) {
                fac = "please enter a valid primary group code"
            }
        }
        setErrorMsg(fac)
    }, [facilitiesData])

  const getCustomerNumber = (value) => {
    if(value === ''){
      setErrorMsg("please enter any primary group code")
    }
    else{    
      setonselectOrg([]);
      dispatch(getFaciltiestCustomerAsyn(value));
      setErrorMsg('')     
    }
  };

  let primarydressingData = [];
  clinicianandproductcategory &&
    Array.isArray(clinicianandproductcategory?.primaryDressing) &&
    clinicianandproductcategory.primaryDressing.map((value, index) => {//NOSONAR
      if (value.options) {
        Array.isArray(value.options)&&value.options.map((valueOpt, indexOpt) => {//NOSONAR
          // primarydressingData.push({ materialDescription: valueOpt.materialDescription, value: valueOpt.productFamilyDesc, cat: value.name + ':', class: 'tests' })
          primarydressingData.push({
            materialDescription: valueOpt.materialDescription,
            value: valueOpt.productFamilyDesc + "-" + valueOpt.materialNumber,
            key: valueOpt.materialNumber,
            cat: value.name + ":",
            class: "tests",
          });
        })//NOSONAR
      }
    })//NOSONAR

  let secondarydressingData = [];
  clinicianandproductcategory &&
    Array.isArray(clinicianandproductcategory.secondaryDressing) &&
    clinicianandproductcategory.secondaryDressing.map((value, index) => {//NOSONAR
      if (value.options) {
        Array.isArray(value.options)&&value.options.map((valueOpt, indexOpt) => {//NOSONAR
          // secondarydressingData.push({ materialDescription: valueOpt.materialDescription, value: valueOpt.productFamilyDesc, key: valueOpt.materialNumber, cat: value.name + ':', class: 'tests' })
          secondarydressingData.push({
            materialDescription: valueOpt.materialDescription,
            value: valueOpt.productFamilyDesc + "-" + valueOpt.materialNumber,
            key: valueOpt.materialNumber,
            cat: value.name + ":",
            class: "tests",
          });
        })//NOSONAR
      }
    })//NOSONAR

  let compresssionData = [];
  clinicianandproductcategory &&
    Array.isArray(clinicianandproductcategory.compression) &&
    clinicianandproductcategory.compression.map((value, index) => {//NOSONAR
      if (value.options) {
        Array.isArray(value.options)&&value.options.map((valueOpt, indexOpt) => {//NOSONAR
          compresssionData.push({
            materialDescription: valueOpt.materialDescription,
            value: valueOpt.productFamilyDesc + "-" + valueOpt.materialNumber,
            key: valueOpt.materialNumber,
            cat: value.name + ":",
            class: "tests",
          });
        })//NOSONAR
      }
    })//NOSONAR

  let skinProtectantData = [];
  clinicianandproductcategory &&
    Array.isArray(clinicianandproductcategory.skinProtectant) &&
    clinicianandproductcategory.skinProtectant.map((value, index) => {//NOSONAR
      if (value.options) {
        Array.isArray(value.options)&&value.options.map((valueOpt, indexOpt) => {  //NOSONAR
          skinProtectantData.push({
            materialDescription: valueOpt.materialDescription,
            value: valueOpt.productFamilyDesc + "-" + valueOpt.materialNumber,
            key: valueOpt.materialNumber,
            cat: value.name + ":",
            class: "tests",
          });
        })//NOSONAR
      }
    })//NOSONAR

  let woundCleansertData = [];
  clinicianandproductcategory &&
    Array.isArray(clinicianandproductcategory.woundCleanser) &&
    clinicianandproductcategory.woundCleanser.map((value, index) => {//NOSONAR
      if (value.options) {
        Array.isArray(value.options)&&value.options.map((valueOpt, indexOpt) => {//NOSONAR
          woundCleansertData.push({
            materialDescription: valueOpt.materialDescription,
            value: valueOpt.productFamilyDesc + "-" + valueOpt.materialNumber,
            key: valueOpt.materialNumber,
            cat: value.name + ":",
            class: "tests",
          });
        })//NOSONAR
      }
    })//NOSONAR

  const [facilityOption, setfacilityOption] = useState(optionData);
  const [facilityOptionDefault, setFacilityOptionDefault] = useState(
    optionData[0]?.key
  );
  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const handleSelect = (selectedList) => {
    // setResetFacility(false);
    if (setSelectedFacilities) {
      let facilitykey = [];
      selectedList &&Array.isArray(selectedList)&&
        selectedList.map((item) => {//NOSONAR
          let facility = item.key.split("|")[0].trim();
          facilitykey = [...facilitykey, facility];
        })//NOSONAR
      setProductManagerData(facilitykey);
      setSelectedFacilities(facilitykey);
      setOrgError(false);
      if (facilitykey.length > 0) {
        dispatch(getClinicianandproductcategory(facilitykey));
        dispatch(getTreatMentBiList(facilitykey));
      }
    } else {
      setProductManagerData([]);
      setSelectedFacilities([]);
      // setOrgError(true)
      dispatch(setTreatMentBiListOption({}));
      dispatch(setClinicianandproductcategory({}));
      clinicianIdRef.current.resetSelectedValues();
      primaryDressingRef.current.resetSelectedValues();
      secondaryDressingRef.current.resetSelectedValues();
      compresssionRef.current.resetSelectedValues();
      woundCleanserRef.current.resetSelectedValues();
      skinProtectantRef.current.resetSelectedValues();
    }
    if(selectedList.length===0){
      setonselectOrg([])
  }else{
    setonselectOrg([...selectedList])
  }
  };

  const OnChangeWoundType = (e) => {
    if (e && e?.length > 0) {
      let arr = [];
      e.map((data) => {
        if (data.key !== "") {
          arr.push(data.key);
        }
      });
      setwoundTypeData(arr);
      dispatch(getTissueTypeandClassification(arr));
    }
  };

  const OnRemoveWoundType = (selectedList, selectedItem) => {
    if (selectedList && selectedList?.length > 0) {
      let arr = [];
      Array.isArray(selectedList)&&selectedList.map((data) => {//NOSONAR
        if (data&&data.key !== "") {
          arr.push(data.key);
        }
      })//NOSONAR
      setwoundTypeData(arr);
      dispatch(getTissueTypeandClassification(arr));
    } else {
      dispatch(setTissuetypeandClassification({}));
      woundClassificationIdRef.current.resetSelectedValues();
      tissueTypesIdRef.current.resetSelectedValues();
      setTissueTypesIdDisable(false);
    }
  };

  const getMultiselectData = (list, select) => {
    let obj = { ...getvalue, ...list };
    setValue(obj);
    if (list.chkType === "woundClassificationId") {
      setTissueTypesIdDisable(true);
    }

    setTimeout(() => {
      if (document.querySelectorAll('input[type="button"].searchBox')) {
        const elem = document.querySelectorAll(
          'input[type="button"].searchBox'
        );

        elem.forEach((descriptionTerm) => {
          descriptionTerm.value = "Select";
        });
      }
    }, 100);
  };
  const removeMultiValue = (list, select) => {
    if (list.length === 0) {
      setTissueTypesIdDisable(false);
    }

    setTimeout(() => {
      if (document.querySelectorAll('input[type="button"].searchBox')) {
        const elem = document.querySelectorAll(
          'input[type="button"].searchBox'
        );
        elem.forEach((descriptionTerm) => {
          descriptionTerm.value = "Select";
        });
      }
    }, 100);
  };

  const handleWoundChange = () => {
    setShowApplyState(true);
  };

  const getHSData = (cat, value) => {
    setShowApplyState(true);
    setPi(cat);
    setSelectedCause(value);
  };

  const handlePayorChange = () => {
    setShowApplyState(true);
  };
  const onClickCheckBox = (e) => {
    setShowApplyState(true);
  };

  const onSubmit = (data) => {
    if (selectedFacilities.length === 0) {
      setOrgError(true);
    } else {
      setOrgError(false);
    }

    const newdata = { ...getvalue, ...data };
    let final_payload = {};
    final_payload.dateFrom = convertnewDateToDate(newdata.datePicker1);

    final_payload.dateTo = convertnewDateToDate(newdata.datePicker2);

    //isDischargePatientChecked
    let includeDischargedPatients = newdata.includeDischargedPatients ? 1 : 0;
    final_payload.isDischargePatientChecked = includeDischargedPatients;

    // isPendingAssessmentsChecked
    let pendingAssessmentsChecked =
      newdata.pendingAssessment === "Pending Assessment" ? 1 : 0;
    final_payload.isPendingAssessmentsChecked = pendingAssessmentsChecked;

    //isPendingReviewChecked
    let pendingReview = newdata.pendingReview === "Pending Review" ? 1 : 0;
    final_payload.isPendingReviewChecked = pendingReview;

    // isSign&LockedChecked
    let completedAssessment =
      newdata.completedAssessment === "Completed Assessment (Signed & Locked)"
        ? 1
        : 0;
    final_payload.isSignLockedChecked = completedAssessment;

    //isPOAChecked
    let poa = newdata.poa === "POA" ? 1 : 0;
    final_payload.isPOAChecked = poa;

    //isNonPOAChecked
    let nonPoa = newdata.nonPoa === "NON-POA" ? 1 : 0;
    final_payload.isNonPOAChecked = nonPoa;

    //facilities
    let facilities =
      selectedFacilities && Array.isArray(selectedFacilities)
        ? selectedFacilities
        : [facilityOptionDefault];
    final_payload.facilities = facilities;

    //clinican
    let clinicianIds = [];
    const clinicianIdsValue =
      Array.isArray(newdata.clinicianId) &&
      newdata.clinicianId.map((value, index) => {
        clinicianIds.push(value.key);
      });
    final_payload.clinican = clinicianIds;

    // assessmentType
    let assessmentIds = [];
    const assessmentIdValue =
      Array.isArray(newdata.assessmentTypeId) &&
      newdata.assessmentTypeId.map((item, index) => {
        assessmentIds.push(item.key);
      });
    final_payload.assessmentType = assessmentIds;

    //photoType
    let photoTypeIds = [];
    const photoTypeIdsValue =
      Array.isArray(newdata.photoTypeId) &&
      newdata.photoTypeId.map((item, index) => {
        photoTypeIds.push(item.key);
      });
    final_payload.photoType = photoTypeIds;

    //woundTypes
    let woundTypeIds =
      woundTypeData && Array.isArray(woundTypeData) ? woundTypeData : "";
    final_payload.woundTypes = woundTypeIds;

    // woundProgress
    let woundProgressIds = [];
    const woundProgressIdsValue =
      Array.isArray(newdata.woundProgressId) &&
      newdata.woundProgressId.map((item, index) => {
        woundProgressIds.push(item.key);
      });
    final_payload.woundProgress = woundProgressIds;

    // woundClassifications
    let woundClassificationIds = [];
    const woundClassificationIdsValue =
      Array.isArray(newdata.woundClassificationId) &&
      newdata.woundClassificationId.map((item, index) => {
        woundClassificationIds.push(item.key);
      });

    final_payload.woundClassifications = woundClassificationIds;

    // bodyAnatomy
    let bodyAnatomyIds = [];
    const bodyAnatomyIdsValue =
      Array.isArray(newdata.bodyAnatomyId) &&
      newdata.bodyAnatomyId.map((item, index) => {
        bodyAnatomyIds.push(item.key);
      });
    final_payload.bodyAnatomy = bodyAnatomyIds;

    // woundAge
    let woundAgeIds = [];
    const woundAgeIdsValue =
      Array.isArray(newdata.woundAgeId) &&
      newdata.woundAgeId.map((item, index) => {
        woundAgeIds.push(item.key);
      });
    final_payload.woundAge = woundAgeIds;

    let tissueTypeIds = [];
    const tissueTypeIdsValue =
      newdata.tissueTypesId && Array.isArray(newdata.tissueTypesId)
        ? newdata.tissueTypesId.map((item, index) => {
            tissueTypeIds.push(item.key);
          })
        : [];
    final_payload.tissueTypes = tissueTypeIds;

    let infected = [];
    const infectedValue =
      Array.isArray(newdata.infectedId) &&
      newdata.infectedId.map((item, index) => {
        if (item.key === "yes") {
          infected.push(0);
        } else {
          infected.push(1);
        }
      });
    final_payload.infected = infected;

    let openArea = [];
    const openAreaValue =
      Array.isArray(newdata.openAreaId) &&
      newdata.openAreaId.map((item, index) => {
        if (item.key === "yes") {
          openArea.push(0);
        } else {
          openArea.push(1);
        }
      });
    final_payload.openArea = openArea;

    let yellowFibrin = [];
    const yellowFibrinValue =
      Array.isArray(newdata.yellowFibrinId) &&
      newdata.yellowFibrinId.map((item, index) => {
        if (item.key === "yes") {
          yellowFibrin.push(0);
        } else {
          yellowFibrin.push(1);
        }
      });
    final_payload.yellowFirbrin = yellowFibrin;

    let exudateTypeIds = [];
    const exudateTypeIdValue =
      Array.isArray(newdata.exudateTypeId) &&
      newdata.exudateTypeId.map((item, index) => {
        exudateTypeIds.push(item.key);
      });
    final_payload.exudateType = exudateTypeIds;

    let biofilimPresent = [];
    const biofilimPresentValue =
      Array.isArray(newdata.biofilimPresentId) &&
      newdata.biofilimPresentId.map((item, index) => {
        if (item.key === "yes") {
          biofilimPresent.push(0);
        } else {
          biofilimPresent.push(1);
        }
      });
    final_payload.biofilimPresent = biofilimPresent;

    let exudateAmountIds = [];
    const exudateAmountIdValue =
      Array.isArray(newdata.exudateAmountId) &&
      newdata.exudateAmountId.map((item, index) => {
        exudateAmountIds.push(item.key);
      });
    final_payload.exudateAmount = exudateAmountIds;

    let hPRWorstTissueId = newdata.HPRWorstTissue;
    final_payload.hprWorstTissueType = hPRWorstTissueId;

    let hprSurfaceAreaId = newdata.HPRSurfaceArea;
    final_payload.hprSurfaceArea = hprSurfaceAreaId;

    let hprIntactValueId = newdata.HPRIntactValueId;
    final_payload.hprIntactValue = hprIntactValueId;

    let woundClouserId = newdata.HPRWoundClosure;
    final_payload.woundClouser = woundClouserId;

    let primaryDressing = [];
    const primaryDressingValue =
      Array.isArray(newdata.primaryDressingId) &&
      newdata.primaryDressingId.map((item, index) => {
        primaryDressing.push(item.key);
      });

    final_payload.primaryDressing = primaryDressing;

    let secondaryDressing = [];
    const secondaryDressingValue =
      Array.isArray(newdata.secondaryDressingId) &&
      newdata.secondaryDressingId.map((item, index) => {
        secondaryDressing.push(item.key);
      });
    final_payload.secondaryDressing = secondaryDressing;

    let compression = [];
    const compressionValue =
      Array.isArray(newdata.compressionId) &&
      newdata.compressionId.map((item, index) => {
        compression.push(item.key);
      });
    final_payload.compression = compression;

    let woundCleanser = [];
    const woundCleanserValue =
      Array.isArray(newdata.woundCleansersId) &&
      newdata.woundCleansersId.map((item, index) => {
        woundCleanser.push(item.key);
      });
    final_payload.woundCleanser = woundCleanser;

    let skinProtectant = [];
    const skinProtectantValue =
      Array.isArray(newdata.skinprotectantId) &&
      newdata.skinprotectantId.map((item, index) => {
        skinProtectant.push(item.key);
      });
    final_payload.skinProtectant = skinProtectant;

    final_payload.queryParam = {
      offSet: 0,
      limit: LIST_VIEW_LIMIT,
    };

    let dressingAppearance = [];
    Array.isArray(newdata.dressingAppearanceId) &&
      newdata.dressingAppearanceId.map((item, index) => {
        dressingAppearance.push(item.key);
      });
    final_payload.dressingAppearance = dressingAppearance;

    let debridement = [];
    Array.isArray(newdata.debridementId) &&
      newdata.debridementId.map((item, index) => {
        debridement.push(item.key);
      });
    final_payload.debridement = debridement;
    let modalities = [];
    Array.isArray(newdata.modalitiesId) &&
      newdata.modalitiesId.map((item, index) => {
        modalities.push(item.key);
      });
    final_payload.modalities = modalities;
    let additionalCare = [];
    Array.isArray(newdata.additionalCareId) &&
      newdata.additionalCareId.map((item, index) => {
        additionalCare.push(item.key);
      });
    final_payload.additionalCare = additionalCare;
    setTimeout(() => {
      if (document.querySelectorAll('input[type="button"].searchBox')) {
        const elem = document.querySelectorAll(
          'input[type="button"].searchBox'
        );
        elem.forEach((descriptionTerm) => {
          descriptionTerm.value = "Select";
        });
      }
    }, 100);
    dispatch(postDashboardAsync(final_payload, history));
  };
  const onReset = () => {
    setonselectOrg([]);
    reset();
    clinicianIdRef.current.resetSelectedValues([]);
    assessmentTypeIdRef.current.resetSelectedValues([]);
    photoTypeIdRef.current.resetSelectedValues([]);
    woundTypesIdRef.current.resetSelectedValues([]);
    woundProgressIdRef.current.resetSelectedValues([]);
    woundClassificationIdRef.current.resetSelectedValues([]);
    woundAgeIdRef.current.resetSelectedValues([]);
    bodyAnatomyIdRef.current.resetSelectedValues([]);
    tissueTypesIdRef.current.resetSelectedValues([]);
    infectedIdRef.current.resetSelectedValues([]);
    openAreaIdRef.current.resetSelectedValues([]);
    yellowFibrinIdRef.current.resetSelectedValues([]);
    exudatedTypesIdRef.current.resetSelectedValues([]);
    biofilimIdRef.current.resetSelectedValues([]);
    exudatedAmountRef.current.resetSelectedValues([]);
    dressingApperanceRef.current.resetSelectedValues([]);
    debrimentRef.current.resetSelectedValues([]);
    modalitiesRef.current.resetSelectedValues([]);
    additionalCareRef.current.resetSelectedValues([]);
    primaryDressingRef.current.resetSelectedValues([]);
    secondaryDressingRef.current.resetSelectedValues([]);
    compresssionRef.current.resetSelectedValues([]);
    woundCleanserRef.current.resetSelectedValues([]);
    skinProtectantRef.current.resetSelectedValues([]);
    setResetFacility(true);
    setTissueTypesIdDisable(false);
  };

  const [onselectcustomerOrgz, setOnselectcustomerOrgz] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [onselectOrgz, setonselectOrg] = useState([]);
  const onSelectOrg = (selectedList) => {
    setonselectOrg([...selectedList]);
  };

  const giveOnCutomSelectText = (id, data) => {
    if (document.getElementById(id) && Array.isArray(data)) {
      let elem = document.getElementById(id);
      elem.querySelector(".searchBox").value = "Select";
      if (elem.querySelectorAll(".optionListContainer ul li.option")) {
        const finalElements = elem.querySelectorAll(
          ".optionListContainer ul li.option"
        );
        data &&
          Array.isArray(data) &&
          data.map((value, index) => {//NOSONAR
            let span_obj = document.createElement("p");
            span_obj.setAttribute("class", `${style.multiselectDescription}`);
            span_obj.innerHTML = value.materialDescription;
            if (finalElements[index]) {
              if (finalElements[index].childNodes.length <= 2) {
                finalElements[index].appendChild(span_obj);
                return
              }
              //finalElements[index].appendChild(span_obj)
            }
          });//NOSONAR
        elem
          .querySelector(".searchBox")
          .classList.add(`${style.multiselectDescriptionSearch}`);
        elem.querySelector(".searchBox").setAttribute("type", "button");
        document.getElementById(id).onclick = function(e) {
          elem.querySelector(".searchBox").value = "Select";
        };
      }
    }
  };

  // const onClickOutsideSelect = (e) => {
  //   if (document.querySelectorAll('input[type="button"].searchBox')) {
  //     const elem = document.querySelectorAll('input[type="button"].searchBox');
  //     elem.forEach(descriptionTerm => {
  //       descriptionTerm.value = "Select"
  //     })
  //   }
  // };

  const onClickOutsideSelect = (e) => {
    setTimeout(() => {
      if (document.querySelectorAll('input[type="button"].searchBox')) {
        const elem = document.querySelectorAll(
          'input[type="button"].searchBox'
        );
        elem.forEach((descriptionTerm) => {
          descriptionTerm.value = "Select";
        });
      }
    }, 100);
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutsideSelect);
    return () =>
      document.removeEventListener("mousedown", onClickOutsideSelect);
  });
  useEffect(() => {
    setTimeout(() => {
      if (
        clinicianandproductcategory?.compression &&
        Array.isArray(clinicianandproductcategory?.compression)
      ) {
        giveOnCutomSelectText("compressionId", compresssionData);
      }
      if (
        clinicianandproductcategory?.compression &&
        Array.isArray(clinicianandproductcategory?.primaryDressing)
      ) {
        giveOnCutomSelectText("primaryDressingId", primarydressingData);
      }
      if (
        clinicianandproductcategory?.compression &&
        Array.isArray(clinicianandproductcategory?.secondaryDressing)
      ) {
        giveOnCutomSelectText("secondaryDressingId", secondarydressingData);
      }
      if (
        clinicianandproductcategory?.compression &&
        Array.isArray(clinicianandproductcategory?.skinProtectant)
      ) {
        giveOnCutomSelectText("skinprotectantId", skinProtectantData);
      }
      if (
        clinicianandproductcategory?.compression &&
        Array.isArray(clinicianandproductcategory?.woundCleanser)
      ) {
        giveOnCutomSelectText("woundCleansersId", woundCleansertData);
      }
    }, 200);
  }, [clinicianandproductcategory]);

  const handleOnBlurFrom = ({ target: { value } }) => {
    const fromDates = new Date(value).getTime();
    const toDates = new Date(
      document.getElementById("datePicker2").value
    ).getTime();
    setToDateRes(new Date(value));
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
      }
    }, 500);
  };
  const handleOnBlurTO = ({ target: { value } }) => {
    const fromDates = new Date(
      document.getElementById("datePicker1").value
    ).getTime();
    const toDates = new Date(value).getTime();
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
        setToDateRes("");
      }
    }, 500);
  };

  return (
    <>
      <Header reportHeading={"Ad-hoc Report"} />
      <div className={`col ${style.wound_dashboard} mt-0`}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.wound_form}>
            <div className={`col-8 ${style.col_spec}`}>
              <label className={`col-5 ${style.label_spec}`}>Date Range:</label>

              <DateTimePicker
                id={"datePicker1"}
                name={"datePicker1"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 ${style.col_spec}`,
                }}
                //selected={startDate}
                customOnChange={customFromDatePickerChange}
                defaultValue={subDays(new Date(startDate), 30)}
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef1}
                errors={errors}
                maxDate={new Date()}
                onBlur={handleOnBlurFrom}
              />
              <DateTimePicker
                id={"datePicker2"}
                name={"datePicker2"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 pr-0 ${style.col_spec}`,
                }}
                defaultValue={new Date()}
                customOnChange={customToDatePickerChange}
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef2}
                minDate={toDateRes ? toDateRes : ""}
                errors={errors}
                maxDate={new Date()}
                onBlur={handleOnBlurTO}
              />
            </div>

            {/* <div className='d-flex'> */}
            {adminAccess && (
              <>
                <TextField
                  id={"customerNumber"}
                  name={"customerNumber"}
                  label={"Primary Group Code:"}
                  classes={{
                    column: `col-8 ${style.col_spec} mt-0`,
                    labelClass: `col-5 col-form-label ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                    inputClassName: `${style.wound_input}  customerNum`,
                  }}
                  type="text"
                  validator={register}
                  errors={errors}
                  listViewError={true}
                  // onBlur={(e) => getCustomerNumber(e.target.value)}
                  customError={errorMsg ? errorMsg : ""}
                  onKeyPress={()=>{
                    setErrorMsg('')
                  }}
                  onChange={()=>{
                    setErrorMsg('')
                  }}
                />
                <div className="mt-2">
                  <span
                    className={`mt-1 ${style.form_span}`}
                    onClick={(e) =>
                      getCustomerNumber(
                        document.getElementById("customerNumber").value
                      )
                    }
                  >
                    Search{" "}
                    <img
                      src={rightArrow}
                      alt=""
                      className={`${style.img_left}`}
                    />
                  </span>
                </div>
              </>
            )}

            <div className={`col-12 m-0 ${style.col_spec}`}></div>

            {optionData &&
              Array.isArray(optionData) &&
              optionData.length === 0 && (
                <Organizational
                  id={"facility"}
                  name={"facility"}
                  label={`Facility/Location:`}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    inputClassName: style.wound_select,
                    labelClassName: `col-5 ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                  }}
                  optionValue={optionData}
                  groupBy="cat"
                  displayValue="key"
                  defaultValue={optionData}
                  onSelect={() => {}}
                  selectAll={true}
                  customError={orgError}
                />
              )}
            {optionData &&
              Array.isArray(optionData) &&
              optionData.length !== 0 && (
                <Organizational
                  id={"facility"}
                  name={"facility"}
                  label={`Facility / Location:`}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    inputClassName: style.wound_select,
                    labelClassName: `col-5 ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                  }}
                  optionValue={optionData}
                  groupBy="cat"
                  displayValue="key"
                  defaultValue={Array.isArray(onselectOrgz)&&onselectOrgz.length>0?onselectOrgz:optionData}
                  onSelect={handleSelect}
                  selectAll={true}
                  customError={orgError}
                />
              )}

            <div className={`col-4 ${style.col_spec}`}></div>

            <div className={`col-8 ${style.col_spec}`}>
              <div className="col-6 offset-5 p-0 mb-4">
                <CheckBox
                  classes={{
                    column: `${style.custom_control} ${style.custom_checkbox} ${style.custom_control_inline} mr-0 ml-0 pl-0`,
                    inputClassName: style.custom_control_input,
                    labelClassName: style.custom_control_label,
                  }}
                  label={"Include Discharged Patients"}
                  type={"checkbox"}
                  id={"checkBoxId"}
                  validator={register}
                  name={"includeDischargedPatients"}
                />
              </div>
            </div>
          </div>

          <div className={style.title_top}>
            <p className={style.bi_title}>Assessment </p>
          </div>

          <div className={style.wound_form}>
            <div
              className={`${style.moreinfo_content} ${style.treatement_lspac}`}
            >
              <div className="col-12 mb-4">
                <CheckBox
                  classes={{
                    column: `${style.custom_control} ${style.custom_checkbox} ${style.custom_control_inline}  pl-0 m-0 mr-1`,
                    inputClassName: style.custom_control_input,
                    labelClassName: style.custom_control_label,
                  }}
                  label={"Pending Assessment"}
                  type={"checkbox"}
                  id={"pendingAssessmentId"}
                  validator={register}
                  name={"pendingAssessment"}
                />
                <CheckBox
                  classes={{
                    column: `${style.custom_control} ${style.custom_checkbox} ${style.custom_control_inline} m-0 mr-1`,
                    inputClassName: style.custom_control_input,
                    labelClassName: style.custom_control_label,
                  }}
                  label={"Pending Review"}
                  type={"checkbox"}
                  id={"pendingReviewId"}
                  validator={register}
                  name={"pendingReview"}
                />
                <CheckBox
                  classes={{
                    column: `${style.custom_control} ${style.custom_checkbox} ${style.custom_control_inline} m-0 mr-1`,
                    inputClassName: style.custom_control_input,
                    labelClassName: style.custom_control_label,
                  }}
                  label={"Completed Assessment (Signed & Locked)"}
                  type={"checkbox"}
                  id={"completedAssessmentId"}
                  validator={register}
                  name={"completedAssessment"}
                />
              </div>

              <MultiSelect
                id={"clinicianId"}
                name={"clinician"}
                label={"Clinician:"}
                classes={{
                  column: `col-6 ${style.col_spec}`,
                  inputClassName: style.basic_select,
                  labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                  inputColumn: `col-7 p-0`,
                }}
                optionValue={
                  clinicianandproductcategoryOld?.clinician
                    ? clinicianandproductcategoryOld.clinician
                    : []
                }
                validator={(e) => {
                  register({ required: true });
                  clinicianIdRef.current = e;
                }}
                defaultValue={resetData}
                showCheckbox={true}
                displayValue="value"
                labelSubText={"Select all that apply"}
                onSelect={(selectedList, selectedItem) =>
                  getMultiselectData({ clinicianId: selectedList })
                }
              />

              <MultiSelect
                id={"assessmentTypeId"}
                name={"assessmentType"}
                label={"Assessment Type:"}
                classes={{
                  column: `col-6 ${style.col_spec}`,
                  inputClassName: style.basic_select,
                  labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                  inputColumn: `col-7 p-0`,
                }}
                optionValue={assessmentTypeData}
                validator={(e) => {
                  register({ required: true });
                  assessmentTypeIdRef.current = e;
                }}
                showCheckbox={true}
                displayValue="value"
                labelSubText={"Select all that apply"}
                onSelect={(selectedList, selectedItem) =>
                  getMultiselectData({ assessmentTypeId: selectedList })
                }
              />

              <MultiSelect
                id={"photoTypeId"}
                name={"photoType"}
                label={"Photo Type:"}
                classes={{
                  column: `col-6 ${style.col_spec}`,
                  inputClassName: style.basic_select,
                  labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                  inputColumn: `col-7 p-0`,
                }}
                optionValue={listphotoType ? listphotoType : []}
                validator={(e) => {
                  register({ required: true });
                  photoTypeIdRef.current = e;
                }}
                showCheckbox={true}
                displayValue="value"
                labelSubText={"Select all that apply"}
                onSelect={(selectedList, selectedItem) =>
                  getMultiselectData({ photoTypeId: selectedList })
                }
              />
            </div>
          </div>

          <div class={style.title_top}>
            <p class={style.bi_title}>Wound </p>
          </div>

          <div className={style.wound_form}>
            <div
              className={`${style.moreinfo_content} ${style.treatement_lspac}`}
            >
              <div className="col-12 mb-4">
                <CheckBox
                  classes={{
                    column: `${style.custom_control} ${style.custom_checkbox} ${style.custom_control_inline} pl-0 m-0 mr-1`,
                    inputClassName: style.custom_control_input,
                    labelClassName: style.custom_control_label,
                  }}
                  label={"POA"}
                  type={"checkbox"}
                  id={"pOAId"}
                  validator={register}
                  name={"poa"}
                />
                <CheckBox
                  classes={{
                    column: `${style.custom_control} ${style.custom_checkbox} ${style.custom_control_inline} ${style.non_PoA} m-0 mr-1`,
                    inputClassName: style.custom_control_input,
                    labelClassName: style.custom_control_label,
                  }}
                  label={"NON-POA"}
                  type={"checkbox"}
                  id={"nONPOAId"}
                  validator={register}
                  name={"nonPoa"}
                />
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-6 p-0">
                    <MultiSelect
                      id={"woundTypesId"}
                      name={"woundTypes"}
                      label={"Wound Types:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={listWoundType ? listWoundType : []}
                      validator={(e) => {
                        register({ required: true });
                        woundTypesIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={OnChangeWoundType}
                      onRemove={OnRemoveWoundType}
                    />

                    <MultiSelect
                      id={"woundClassificationId"}
                      name={"woundClassification"}
                      label={"Wound Classification:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={
                        listtissuetypeandClassification?.woundClassification
                          ? listtissuetypeandClassification.woundClassification
                          : []
                      }
                      validator={(e) => {
                        register({ required: true });
                        woundClassificationIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(
                        selectedList,
                        selectedItem,
                        chkType = "woundClassificationId"
                      ) =>
                        getMultiselectData({
                          woundClassificationId: selectedList,
                          chkType: chkType,
                        })
                      }
                      onRemove={(list, selected) =>
                        removeMultiValue(list, selected)
                      }
                    />

                    <MultiSelect
                      id={"woundAgeId"}
                      name={"woundAge"}
                      label={"Wound Age:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={listwoundAge ? listwoundAge : []}
                      validator={(e) => {
                        register({ required: true });
                        woundAgeIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ woundAgeId: selectedList })
                      }
                    />
                  </div>
                  <div className="col-6 p-0">
                    <MultiSelect
                      id={"woundProgressId"}
                      name={"woundProgress"}
                      label={"Wound Progress:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={listwoundProgress ? listwoundProgress : []}
                      validator={(e) => {
                        register({ required: true });
                        woundProgressIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ woundProgressId: selectedList })
                      }
                    />

                    <MultiSelect
                      id={"bodyAnatomyId"}
                      name={"bodyAnatomy"}
                      label={"Body Anatomy:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={listHumanAnatomy ? listHumanAnatomy : []}
                      validator={(e) => {
                        register({ required: true });
                        bodyAnatomyIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ bodyAnatomyId: selectedList })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={`col-12 ${style.col_spec}`}>
                <p className={`col-12 ${style.subheading_2}`}>
                  Characteristics
                </p>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-6 p-0">
                    <MultiSelect
                      id={"tissueTypesId"}
                      name={"tissueTypes"}
                      label={"Tissue Types:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={
                        listtissuetypeandClassification?.tissueType
                          ? listtissuetypeandClassification.tissueType
                          : []
                      }
                      validator={(e) => {
                        register({ required: true });
                        tissueTypesIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ tissueTypesId: selectedList })
                      }
                      disabled={tissueTypesIdDisable}
                    />
                    <MultiSelect
                      id={"openAreaId"}
                      name={"openArea"}
                      label={"Open Area:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={booleanOptions}
                      validator={(e) => {
                        register({ required: true });
                        openAreaIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ openAreaId: selectedList })
                      }
                    />
                    <MultiSelect
                      id={"exudateTypeId"}
                      name={"exudateType"}
                      label={"Exudate Type:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={listexudateType ? listexudateType : []}
                      validator={(e) => {
                        register({ required: true });
                        exudatedTypesIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ exudateTypeId: selectedList })
                      }
                    />
                    <MultiSelect
                      id={"exudateAmountId"}
                      name={"exudateAmount"}
                      label={"Exudate Amount:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={listexudateAmount ? listexudateAmount : []}
                      validator={(e) => {
                        register({ required: true });
                        exudatedAmountRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ exudateAmountId: selectedList })
                      }
                    />
                  </div>
                  <div className="col-6 p-0">
                    <MultiSelect
                      id={"infectedId"}
                      name={"infected"}
                      label={"Infected:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={booleanOptions}
                      validator={(e) => {
                        register({ required: true });
                        infectedIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ infectedId: selectedList })
                      }
                    />
                    <MultiSelect
                      id={"yellowFibrinId"}
                      name={"yellowFibrin"}
                      label={"Yellow Fibrin:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={booleanOptions}
                      validator={(e) => {
                        register({ required: true });
                        yellowFibrinIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ yellowFibrinId: selectedList })
                      }
                    />

                    <MultiSelect
                      id={"biofilimPresentId"}
                      name={"biofilimPresent"}
                      label={"Biofilim Present:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={booleanOptions}
                      validator={(e) => {
                        register({ required: true });
                        biofilimIdRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ biofilimPresentId: selectedList })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={`col-12 ${style.col_spec}`}>
                <p className={`col-12 ${style.subheading_2}`}>Score</p>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className={` col-12 p-0  ${style.form_custom_check} `}>
                      <TextField
                        id={"HPRWorstTissueId"}
                        name={"HPRWorstTissue"}
                        label={`HPR - Worst Tissue Type:`}
                        classes={{
                          column: `col-12 ${style.col_spec}`,
                          labelClass: `col-5 pr-0 col-form-label ${style.label_spec}`,
                          inputColumn: `col-7  ${style.bi_arrow_bg}`,
                          inputClassName: `${style.type_basic_input} ${style.input_block} `,
                        }}
                        validator={register(checkNumbericValue)}
                        errors={errors}
                        placeholder={"Enter a value"}
                      />
                    </div>
                    <div className={` col-12 p-0  ${style.form_custom_check} `}>
                      <TextField
                        id={"HPRIntactValueId"}
                        name={"HPRIntactValueId"}
                        label={`HPR - Intact Value:`}
                        classes={{
                          column: `col-12 ${style.col_spec}`,
                          labelClass: `col-5 col-form-label ${style.label_spec}`,
                          inputColumn: `col-7  ${style.bi_arrow_bg}`,
                          inputClassName: `${style.type_basic_input} ${style.input_block} `,
                        }}
                        validator={register(checkNumbericValue)}
                        errors={errors}
                        placeholder={"Enter a value"}
                      />
                    </div>
                    <div className={` col-12 p-0  ${style.form_custom_check} `}>
                      <TextField
                        id={"HPRWoundClosureId"}
                        name={"HPRWoundClosure"}
                        label={`% Wound Closure:`}
                        classes={{
                          column: `col-12 ${style.col_spec}`,
                          labelClass: `col-5 col-form-label ${style.label_spec}`,
                          inputColumn: `col-7  ${style.bi_arrow_right_bg}`,
                          inputClassName: `${style.type_basic_input} ${style.input_block} `,
                        }}
                        validator={register(checkPercentageNumbericValue)}
                        errors={errors}
                        placeholder={"%"}
                      />
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className={` col-12 p-0  ${style.form_custom_check} `}>
                      <TextField
                        id={"HPRSurfaceAreaId"}
                        name={"HPRSurfaceArea"}
                        label={`HPR - Surface Area:`}
                        classes={{
                          column: `col-12 ${style.col_spec}`,
                          labelClass: `col-5 col-form-label ${style.label_spec}`,
                          inputColumn: `col-7  ${style.bi_arrow_bg}`,
                          inputClassName: `${style.type_basic_input} ${style.input_block} `,
                        }}
                        validator={register(checkNumbericValue)}
                        errors={errors}
                        placeholder={"Enter a value"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class={style.title_top}>
            <p class={style.bi_title}>Treatment</p>
          </div>
          <div className="col-12">
            <div
              className={`${style.moreinfo_content} ${style.treatement_lspac}`}
            >
              <div className="col-12">
                <div className="row">
                  <div className="col-6 p-0">
                    <MultiSelect
                      id={"dressingAppearanceId"}
                      name={"dressingAppearance"}
                      label={"Dressing Appearance:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={
                        listdressingappearanceListoptions
                          ? listdressingappearanceListoptions
                          : []
                      }
                      validator={(e) => {
                        register({ required: true });
                        dressingApperanceRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({
                          dressingAppearanceId: selectedList,
                        })
                      }
                    />
                    <MultiSelect
                      id={"modalitiesId"}
                      name={"modalities"}
                      label={"Modalities:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={
                        listmodalitiesListoptions
                          ? listmodalitiesListoptions
                          : []
                      }
                      validator={(e) => {
                        register({ required: true });
                        modalitiesRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ modalitiesId: selectedList })
                      }
                    />
                    <MultiSelect
                      id={"primaryDressingId"}
                      name={"primaryDressing"}
                      label={"Primary Dressing:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={primarydressingData}
                      validator={(e) => {
                        register({ required: true });
                        primaryDressingRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ primaryDressingId: selectedList })
                      }
                      groupBy={"cat"}
                      placeholder={"Select"}
                    />
                    <MultiSelect
                      id={"compressionId"}
                      name={"compression"}
                      label={"Compression:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={compresssionData}
                      validator={(e) => {
                        register({ required: true });
                        compresssionRef.current = e;
                      }}
                      placeholder={"Select"}
                      groupBy={"cat"}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ compressionId: selectedList })
                      }
                    />
                    <MultiSelect
                      id={"skinprotectantId"}
                      name={"skinprotectant"}
                      label={"Skin protectant:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={skinProtectantData}
                      validator={(e) => {
                        register({ required: true });
                        skinProtectantRef.current = e;
                      }}
                      groupBy={"cat"}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ skinprotectantId: selectedList })
                      }
                    />
                  </div>
                  <div className="col-6 p-0">
                    <MultiSelect
                      id={"debridementId"}
                      name={"debridement"}
                      label={"Debridement:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={listdebridement ? listdebridement : []}
                      validator={(e) => {
                        register({ required: true });
                        debrimentRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ debridementId: selectedList })
                      }
                    />
                    <MultiSelect
                      id={"additionalCareId"}
                      name={"additionalCare"}
                      label={"Additional Care:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={
                        listadditionalCareListoptions
                          ? listadditionalCareListoptions
                          : []
                      }
                      validator={(e) => {
                        register({ required: true });
                        additionalCareRef.current = e;
                      }}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ additionalCareId: selectedList })
                      }
                    />
                    <MultiSelect
                      id={"secondaryDressingId"}
                      name={"secondaryDressing"}
                      label={"Secondary Dressing:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={secondarydressingData}
                      validator={(e) => {
                        register({ required: true });
                        secondaryDressingRef.current = e;
                      }}
                      groupBy={"cat"}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({
                          secondaryDressingId: selectedList,
                        })
                      }
                    />

                    <MultiSelect
                      id={"woundCleansersId"}
                      name={"woundCleansers"}
                      label={"Wound Cleansers:"}
                      classes={{
                        column: `col-12 ${style.col_spec}`,
                        inputClassName: style.basic_select,
                        labelClassName: `col-5 col-form-label ${style.label_spec} pt-0 pb-0`,
                        inputColumn: `col-7 p-0`,
                      }}
                      optionValue={woundCleansertData}
                      validator={(e) => {
                        register({ required: true });
                        woundCleanserRef.current = e;
                      }}
                      groupBy={"cat"}
                      showCheckbox={true}
                      displayValue="value"
                      labelSubText={"Select all that apply"}
                      onSelect={(selectedList, selectedItem) =>
                        getMultiselectData({ woundCleansersId: selectedList })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={`col-12 ${style.col_spec}`}>
                <div className="text-center w-100">
                  {/* Save Button */}
                  <Button
                    classes={{
                      className: `btn btn-custom btn-small mr-4 ${style.primary_Save}`,
                    }}
                    type="submit"
                    id={"applyId"}
                  >
                    Run Report
                  </Button>
                  {/* Cancel button  */}
                  <Button
                    classes={{
                      className: `btn btn-custom btn-small-cancel ${style.secondary_Reset}`,
                    }}
                    id={"cancelId"}
                    onClick={onReset}
                    // onClick={handleClear}
                    type="button"
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}//NOSONAR
