import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { EDIT, UPDATE_SUCCESS, UPDATE_FAILURE, SUCCESS_STATUS, ERROR_STATUS, ROLE_LBL, ROLE_ID} from '../../../constants'
import editIcon from '../../../static/images/edit-icon-blue.png'
import closeIcon from "../../../static/images/Close_Normal.svg"; // added Travel Nurse
import { phoneMasker, getFacilityLabel, getFacilityLabelUrl } from '../../../utils'
import styles from './basicInfo.module.scss'
import { CreateEditUser } from './createEditUser'
import { setEditState, getFaciltiestUserAsyn } from '../../../app/userSlice';
import { AccessErrorModal, Button, SelectBox } from '../../primary';
import { setDuplicateData } from '../../../app/formularySlice';
import Loader from '../../../static/images/spinner.svg';

export function BasicInfo({ userData, facilityOptions, updateUserData, getSearchValue , searchKey, isModal = false}) {
    const { userId } = useParams();
    /* State to check editable forms */
    const [editable, setEditable] = useState(false)
    const [disable, setDisable] = useState(false)
    const [selectedFacilities, setSelectedFacilities] = useState([])
    /* added for TravelNurse */
     const [getTodoList, setGetTodoList] = useState([]);
     const [getTraveNurse, setGetTraveNurse] = useState(userData?.isTravelNurse === 1 ? 1 : 0)
     const [getOrgError, setGetOrgError] = useState(false); // error throw
     const [getErrorMsg, setGetErrorMsg] = useState('');
    
    /* Function to toggle edit */
    const handleEdit = () => {
        setEditable(!editable)
        setDisable(!disable);
    }

    /* Use hook form */
    const { register, handleSubmit, control, errors } = useForm({
        mode: 'onBlur'
    });

    /*************************
         * Phone number field
         *************************/
    /* Get value from phone field using useREf */
    let phoneField = useRef();
    /* If phone number is typed call the above phoneHandle function */
    if (phoneField.current) {
        if (phoneField.current.value.length > 9) {
            phoneField.current.value = phoneMasker(phoneField.current.value)
        }
    }

    const userDetails = useSelector(state => state.user);
    const adminView = userDetails?.role
    const [externalAdmin, setExternalAdmin] = useState(false);
    const [isValidSaleCust, setIsValidSaleCust] = useState(false);
   

    useEffect(() => {
        if (adminView === "ExternalAdministrator") {
            setExternalAdmin(true)
        }
    }, [adminView])

    useEffect(() => {
        if (userData?.customerNumber) {
            const payload = { customer: userData?.customerNumber,}
            dispatch(getFaciltiestUserAsyn(payload));
        }

    }, [userData])
    // facility data from state.
    const getFacilities = useSelector(state => state.user.userFacilities);

    let facilityData = "";

    (getFacilities.data) && getFacilities.data.facilities.map((value, index) => {
        facilityData = `${value.facilityNumber}| ${value.facilityName} | ${value.address?.addressLine1} | ${value.address?.addressLine2} | ${value.address?.city} | ${value.address?.state} | ${value.address?.zipCode}`;
    })

    /* On Submit */
    const onSubmit = (data) => {               
        handleEdit();
        const tempUserData = {
            id: userId,
            facilities: selectedFacilities,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            role: data.roleID == undefined ? 'MedlineAdministrator' : data.roleID,            
            isTravelNurse : null,
            defaultCustomerId : userData.customerId,
            customerNumber : data.customerNumber            
        }
        if(getTraveNurse === 1){            
           tempUserData.isTravelNurse = 1;
           tempUserData.defaultCustomerId =  getTodoList?.find(f=>f.isDefault === true)?.customerId;
           tempUserData.customerNumber =  getTodoList?.find(f=>f.isDefault === true)?.customerNumber;
           tempUserData.facilities = [];
        //    getTodoList?.map((item)=>{
        //     {item?.facilities?.map((list)=>{tempUserData.facilities.push(list.facilityNumber)})}
        //    })
            getTodoList?.map((item)=>{
                return (item?.facilities?.map((list)=>{tempUserData.facilities.push(list.facilityNumber)}))
            })
        }
        /* Merging */
        const result = { ...userData, ...tempUserData }
        /* Dispatching */
        updateUserData(result);        
    }


    /* Edit success */
    const editState = useSelector(state => state.user.editState)

    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            dispatch(setEditState(false))
        }, 5000)
    }, [editState, dispatch])

    const facilitiesData = useSelector(state => state.user.userFacilities);    
    const assignedFacilities = () => {
        const getSelList = userData?.customer?.filter((item)=> item.customerNumber.toLowerCase() === userData?.customerNumber?.toLowerCase())        
        const defaultFac = getSelList?.length > 0 ? getSelList[0]?.facilities : null;
        let defaultFacArr = [];
        const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
        soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
            const shipToFacilities = facilitiesData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
            if(shipToFacilities.length===0){
                defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                    if (shipTofacilityNumber.facilityNumber === soldToFacility.facilityNumber) {
                defaultFacArr.push(<div className={`col-form-label ${styles.content_spec}`}>               
                {getFacilityLabelUrl(soldToFacility)}                
            </div>            
            )
                    }
             })
               }
            shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
                defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                    if (shipTofacilityNumber.facilityNumber === shipToFacility.facilityNumber) {
                        defaultFacArr.push(<div className={`col-form-label ${styles.content_spec}`}>
                        {getFacilityLabelUrl(shipToFacility)}                       
                    </div>)
                    }
                })
            })
        })

         
        /*const facilities = [];
        userData?.facilities?.forEach((soldTo) => { //NOSONAR
            const soldToFacility = facilitiesData?.data?.facilities?.find((f) => f.facilityNumber === soldTo.soldToNumber) //NOSONAR
            soldToFacility && facilities.push(<div className={`col-form-label ${styles.content_spec}`}>
                {getFacilityLabel(soldToFacility)}
            </div>);
            const shipToFacilities = facilitiesData?.data?.facilities?.filter((f) => soldTo.includes(f.facilityNumber)) //NOSONAR
            shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
                facilities.push(<div className={`col-form-label ${styles.content_spec}`}>
                    {getFacilityLabel(shipToFacility)}
                </div>);
            });
        }) //NOSONAR
        return facilities;*/


        return defaultFacArr;
    }

     //get user role data from state
     const userRoleData = useSelector(state => state.user.userRole);
     // convert key value pair instead of name and label. 
     const convertedUserRoleData = (Object.keys(userRoleData).length !== 0) ? userRoleData.map(data => ({ key: data.name, value: data.label })) : '';
    
    const roleName = userRoleData && Array.isArray(userRoleData) && userRoleData.filter((f) => f.name === userData?.role)
    const [tinyDelay, setTinyDelay] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])
    return (
        <>
            <AccessErrorModal />
            {!editable && (
                <div className={`${styles.tab_content_top}`}>
                <h2 className={`${styles.tab_content_hdg}`}>
                    {userData?.firstName + ' ' + userData?.lastName}
                </h2>
                {/* Role */}  
                <div className={`col-12 d-flex ${styles.col_spec}`}>                   
                    <div className="col-6 px-2">
                        <span className={`col-form-label ${styles.content_spec}`}>
                            <b>Role: </b>{roleName && roleName[0] ? roleName[0].label : userData?.role}                            
                            {userData?.isTravelNurse === 1 ? ` | Traveling Nurse`: ""}
                        </span>
                    </div>
                    <div className="col-6 p-0">
                    {tinyDelay&&!editable &&
                            <span className={`float-sm-right ${styles.tab_edit}`} onClick={handleEdit} >
                                <img src={editIcon} alt="edit" />{EDIT}
                            </span>
                        }
                    </div>                        
                </div> 
            </div>
            )}
            
            {!tinyDelay?<div className={`${styles.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
            <div className={`col-md-12 single_product_block ${styles.single_product_block}`}>
                <div className="row">
                    <div className={`p-0 ${styles.patient_content}`}>
                        {!editable ?
                            <>
                                {/* Edit success */}
                                {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{UPDATE_SUCCESS}</div>}

                                {/* Edit failure */}
                                {editState === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}
                                
                                {/* Contact Information Heading */}
                                <div className={`col-sm-12 mt-3 ${styles.col_spec}`}>
                                    <p className={`col-12 ${styles.subheading_2}`}>Contact Information</p>
                                </div>                        

                                {/* First Name */}
                                <div className={`col-6 ${styles.col_spec}`} data-testid="first_name">
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>First Name: </label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.firstName}</span></div>
                                </div>

                                {/* Last Nmae*/}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>Last Name: </label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.lastName}</span></div>
                                </div>

                                {/* Email */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>Email: </label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}><a href={`/admin/customers/${userData?.customerId}`} target="_blank">{userData?.email}</a></span></div>
                                </div>

                                {/* Phone */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>Phone Number: </label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.phone}</span></div>
                                </div>

                                {/* Customer Information Heading*/}
                                <div className={`col-sm-12 mt-4 ${styles.col_spec}`}>
                                    <p className={`col-12 ${styles.subheading_2}`}>Customer Information</p>
                                </div>

                                {/* <div className={`col-6 ${styles.col_spec}`}></div> */}
                                {/* Organizational View*/}
                                {/* <div className={`col-12 ${styles.col_spec}`}>
                                        <label className={`col-3 col-form-label ${styles.label_spec}`}>Facility/Location:</label>
                                        <div className="col-7 p-0">
                                            {assignedFacilities().length > 0 ? assignedFacilities() : '-'}
                                        </div>                                    
                                </div>   */}

                                {userData?.isTravelNurse === 1 ? (
                                    <div className={`col-12 ${styles.col_spec}`}>
                                    {/* Customer Number & Facility/Locations*/}
                                        {userData && userData?.customer?.map((item)=>{
                                            return(
                                                <div className={`col-12 d-flex mb-2 ${styles.col_spec}`}>  
    
                                                    <div className={`col-6 ${styles.col_spec}`}>                                                    
                                                        <label className={`col-6 col-form-label ${styles.label_spec}`}>Customer Number: </label>
                                                        {item?.customerNumber === userData?.customerNumber ? (
                                                            <div className="col-6 p-0" id={"customerNumber"}>
                                                            <span className={`col-form-label ${styles.content_spec}`}>
                                                            <a href={`/admin/customers/${item?.customerId}`} target="_blank">{item?.customerNumber}</a>
                                                                <Button
                                                                    classes={{ className: `btn ${styles.btn_toggle} ml-3` }}
                                                                    type="submit"                                                                
                                                                >
                                                                    Default
                                                                </Button>
                                                            </span>
                                                        </div>
                                                        ): (
                                                            <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}><a href={`/admin/customers/${item?.customerId}`} target="_blank">{item.customerNumber}</a></span></div>                                                 
                                                        )}                                                    
                                                    </div>
                                                    <div className={`col-6 ${styles.col_spec}`}></div>
    
    
                                                    <div className={`col-12 ${styles.col_spec}`}>
                                                        <label className={`col-3 col-form-label ${styles.label_spec}`}>Facility/Locations: </label> 
                                                        <div className={`col-9 ${styles.col_spec}`}>
                                                        {item?.facilities?.map((fac)=>{
                                                            return(
                                                                <div className={`row ${styles.content_spec}`}>                            
                                                                    <div className={`col-12 pl-2 col-form-label ${styles.content_spec}`}>               
                                                                    {getFacilityLabelUrl(fac)}                
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            )
                                        })}    
                                    </div>
                                    ) : (
                                        <div className={`col-12 ${styles.col_spec}`}>
                                        <div className={`col-6 ${styles.col_spec}`}>                                                    
                                            <label className={`col-6 col-form-label ${styles.label_spec}`}>Customer Number: </label>                                        
                                            <div className="col-6 pl-1 p-0"><span className={`col-form-label ${styles.content_spec}`}><a href={`/admin/customers/${userData?.customerId}`} target="_blank">{userData?.customerNumber}</a></span></div>                                                                                                
                                        </div>
                                        <div className={`col-6 ${styles.col_spec}`}></div>


                                        <div className={`col-12 ${styles.col_spec}`}>
                                            <label className={`col-3 col-form-label ${styles.label_spec}`}>Facility/Locations: </label> 
                                            <div className={`col-9 ${styles.col_spec}`}>
                                                {assignedFacilities().length > 0 ? assignedFacilities() : '-'}                                            
                                            </div>
                                        </div>
                                        {/* <div className={`col-11 ${styles.col_spec} pt-3`}>
                                            <label className={`col-3 col-form-label ${styles.label_spec}`}>Facility/Locations: </label>
                                            <div className={`col-8 ${styles.col_spec} pl-2 ml-4 d-block`}>
                                                {userData?.customer?.map((item)=>item?.facilities)?.map((list)=>list?.map((fac)=>{
                                                        return(
                                                            <div className={`row ${styles.content_spec} d-block`}>                            
                                                                <div className={`col-12 pl-1 col-form-label ${styles.content_spec}`}>               
                                                                    {getFacilityLabelUrl(fac)}   
                                                                </div>
                                                            </div>
                                                        )
                                                    }))
                                                
                                                } 
                                                {userData?.facilities?.length===0 &&
                                                    <div className={`col-12 px-0 col-form-label ${styles.content_spec}`}>               
                                                        {"-"}   
                                                    </div>  
                                                }                                   
                                            </div>
                                        </div> */}

                                    </div>
                                    )
                                }       

                            </>
                            :
                            <CreateEditUser
                                register={register}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                control={control}
                                errors={errors}
                                userData={userData}
                                handleCancel={handleEdit}
                                externalAdmin={externalAdmin}
                                facilityOptions={facilityOptions}
                                setSelectedFacilities={setSelectedFacilities}
                                getSearchValue={getSearchValue}
                                searchKey = {searchKey}
                                assignedFacilities = {()=>assignedFacilities()}
                                // assignedFacilities = {(e)=>assignedFacilities(e)}
                                setGetTodoList = {setGetTodoList}
                                setGetTraveNurse = {setGetTraveNurse}
                                editState = {editState}
                                setGetOrgError = {setGetOrgError}
                                facilitiesData = {facilitiesData}                                
                            />
                        }
                    </div>
                </div>
            </div>}
        </>
    )
}
