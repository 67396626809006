import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import style from './header.module.scss';
import UserProfile from '../../../static/images/picture-icon-default.png'
import { getFaciltiestUserAsyn, updateUserDetailsAsync } from '../../../app/userSlice';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Notification } from './notification'
//import { getFacilityLabel } from '../utils';
import { getFacilityLabel } from '../../../../src/utils';
import { setAccessError } from '../../../app/appSlice';
import LogoImage from '../../../static/images/medline-logo.svg';
import MenuIcon from '../../../static/images/menu_logo.svg';

export let Header = ({ heading, subHeading, handleBack, backLink, dispatch, user, workListView = false, formulary, createFormulary, reportHeading, setCreateFormulary }) => { 
    window.onload = function() {
        setMenuClick(false)
        if(!menuClick && document.getElementById('check_left_block')){
            document.getElementById('check_left_block').style.display='none'
        }
      }
    /* State to check drop down assw */
    const [viewDrop, setDrop] = useState(false)
    const backToHistory = useHistory();
    const useLocationChk = useHistory();
    const [checkFormularyList, setCheckFormularyList] = useState('')

    const origin = window.location.href;
    const homeOrigin = window.location.origin;
    
    useEffect(() => {
        setMenuClick(false)
        if(!menuClick && document.getElementById('check_left_block')){
            document.getElementById('check_left_block').style.display='none'
        }
    }, [origin])

    useLayoutEffect(() => {
        if(backToHistory&&backToHistory.location.state&&backToHistory.location.state.refPath&&backToHistory.location.state.refPath==='admin/formulary/'){
            setCheckFormularyList(backToHistory.location.state)
        }
    }, [])

    useEffect(()=>{
        setMenuClick(false)
        if(!menuClick && document.getElementById('check_left_block')){
            document.getElementById('check_left_block').style.display='none'
        }
    },[])

    /* Function to toggle drop assessment*/
    const handleDrop = (e) => {
        setDrop(!viewDrop)
    }

    /* Trigger Drop Down Items close */
    const dropRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setDrop(false);
        }
    };

    // Display user details in worklist page
    const userDetails = useSelector(state => state.user);
    let customerNumber = 0;
    if (userDetails?.customerNumber) {
        customerNumber = userDetails?.customerNumber
    }
    useLayoutEffect(() => {
        if(customerNumber){
            const payload = { customer: customerNumber }
            dispatch(getFaciltiestUserAsyn(payload));
        }
    }, [customerNumber])
    //const getFacilities = useSelector(state => state.user.userFacilities);
    const getFacilities = useSelector(state => state.patient.newFacilitiesData);
    //const facilityData = getFacilities && getFacilities.data?.facilities[0] ? getFacilities.data?.facilities[0] : null;
    const otherPageFacilityCheck = useSelector(state => state.patient.otherPageFacilityCheck);
    const chechFaciData = otherPageFacilityCheck && getFacilities && getFacilities.data && Array.isArray(getFacilities.data.facilities) && getFacilities.data.facilities.filter((f) => f.facilityNumber === otherPageFacilityCheck)
    const facilityData = chechFaciData && chechFaciData[0] ? chechFaciData[0] : null;
    const Details = useSelector(state => state.user.userDetails);
    useLayoutEffect(() => {
        dispatch(updateUserDetailsAsync())
    }, [])

    const handleClickBacktrig = () => {
        dispatch(setAccessError(null))
        backToHistory.goBack()
    };

    const handleFormularyBack = () => {
        setCreateFormulary(false)
    };
        
    //set click on menu icon menu coming
    const [menuClick, setMenuClick] = useState(false)   
    useEffect(()=>{
        if(menuClick && document.getElementById('check_left_block')?.style.display==='none'){
            document.getElementById('check_left_block').style.display='block';
            document.getElementById('check_left_block').style.transition = 'all 0.1s';
        }
        if(!menuClick && document.getElementById('check_left_block')?.style.display==='block'){
            document.getElementById('check_left_block').style.display='none';
            document.getElementById('check_left_block').style.transition = 'all 0.1s';
        }
    },[menuClick])
    
    //set click on close icon menu bar closing
    useEffect(() => {
        let menuValue = document.getElementById('closeMenuButton')
            menuValue?.addEventListener('click', function () {  
                setMenuClick(false)
        });
    });
    useEffect(() => {
        let menuValue = document.getElementById('login-btn')
            menuValue?.addEventListener('click', function () {  
                setMenuClick(false)
                document.getElementById('check_left_block').style.display='none';
        });
    });

    //left sife Menu disappear when creating records for all pages...
    useEffect(() => {
        //Popup Button take ID value
        let menuValue = document.getElementById('forgot-btn')
          menuValue?.addEventListener('click', function () {  
              setMenuClick(false)
         });
         //Formulary after creating formulary then go to Back button take ID value
        let menuFormuBackValue = document.getElementById('formularBack')
        menuFormuBackValue?.addEventListener('click', function () {  
            setMenuClick(false)
        });
        //Right side click close menu
        let menuRightSide = document.getElementById('check_right_block')
        menuRightSide?.addEventListener('click', function () {  
            setMenuClick(false)
        });
    }); 

    return <div className={style.header}>
        <nav className={`navbar navbar-expand-lg navbar-dark ${style.custom_nav}`}>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarColor01"
                aria-controls="navbarColor01"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            {!workListView &&
                <div className="w-auto col-lg-10 p-0">
                    <div className={`d-flex align-items-center ${style.main_logo}`}>
                        <div className={`${style.medline_log}`}>
                            <a href={`${homeOrigin}`}><img src={LogoImage} alt="Logo" className="img-fluid" /></a>
                        </div>
                        <div 
                            className={`${style.menu_log}`} 
                            id={'menuButton'}
                            onClick={() => setMenuClick(true)}
                        >
                            <img src={MenuIcon} id={'menuButton'} onClick={() => setMenuClick(true)} alt="Logo" className="img-fluid" />
                        </div>
                        <div className={`${style.menu_content}`} id={'menuButton'} onClick={() => setMenuClick(true)}>
                            <p onClick={() => setMenuClick(true)} id={'menuButton'}>Menu</p>
                        </div>
                    </div>

                    {/* <div className="w-auto">{backLink && <Link to={backLink} onClick={handleBack}>{"Back"}</Link>}</div> */}
                    {!createFormulary && <div className="w-auto">{backLink && <Link id="formularBack" to={(checkFormularyList)?{pathname:"/admin/formulary",state: {dyList: checkFormularyList.dyList}}:false} onClick={() => handleClickBacktrig()}>{"Back"}</Link>}</div>}
                    {createFormulary && <div className="w-auto">{backLink && <Link id="formularBack" to="/admin/formulary" onClick={() => handleFormularyBack()}>{"Back"}</Link>}</div>}
                    {
                        heading && <h1 className="w-auto">
                            <span className={style.admin_heading}>{heading}</span> {subHeading && '|'} <span className={style.admin_subheading}>{subHeading}</span>
                        </h1>
                    }

                    {reportHeading && <div className={style.admin_subheading}>{reportHeading}</div>}
                </div> }
                {workListView &&
                <div className="w-auto col-lg-10 p-0">
                    <div className={`d-flex align-items-center ${style.main_logo}`}>
                        <div className={`${style.medline_log}`}>
                            <a href={`${homeOrigin}`}><img src={LogoImage} alt="Logo" className="img-fluid" /></a>
                        </div>
                        <div 
                            className={`${style.menu_log}`} 
                            id={'menuButton'}
                            onClick={() => setMenuClick(true)}
                        >
                            <img src={MenuIcon} id={'menuButton'} onClick={() => setMenuClick(true)} alt="Logo" className="img-fluid" />
                        </div>
                        <div className={`${style.menu_content}`} id={'menuButton'} onClick={() => setMenuClick(true)}>
                            <p onClick={() => setMenuClick(true)} id={'menuButton'}>Menu</p>
                        </div>
                    </div>

                    <div className="w-auto">{backLink && <Link onClick={() => handleClickBacktrig()}>{"Back"}</Link>}</div>
                    {/* <div className="w-auto">{backLink && <Link to={backLink} onClick={handleBack}>{"Back"}</Link>}</div> */}
                    <h2 className="w-auto">
                        {/* <span className={style.admin_heading}>{heading}</span> {subHeading && '|'} <span className={style.admin_subheading}>{subHeading}</span> */}
                        <span className={style.tab_hdg}>{(userDetails && userDetails.firstName !== null) ? userDetails.firstName + ' ' + userDetails.lastName : ''}</span>
                        <span className={style.tab_content_subline}>
                            <strong>Facility/Location: </strong>
                            {facilityData ? getFacilityLabel(facilityData) : ''}
                        </span>
                    </h2>
                </div>
            }

            {!formulary && <div className="w-auto col-lg-2 text-lg-right  p-0">
                <Notification dispatch={dispatch} user={user}></Notification>
                <button className={`d-inline ${style.userpic_blk}`}>
                    <img
                        className={`${style.p_link} ${style.user_picture}`}
                        src={Details?.profilePicture ? Details?.profilePicture : UserProfile}
                        onClick={handleDrop} ref={dropRef} alt="User profile"
                    />
                    {viewDrop &&
                        <div className={`dropdown-menu dropdown-menu-right show p-0 m-0 ${style.medline_menu}`} ref={dropRef}>
                            <Link to="/userprofile"><div className={`${style.link} ${style.list_menu}`} >User Profile</div></Link>
                        </div>
                    }
                </button>
            </div>}
        </nav>
    </div>

}

Header.propTypes = {
    dispatch: PropTypes.func,
    workListView: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

Header = connect(mapStateToProps)(Header);
