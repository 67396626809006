import { subMonths, subDays, subYears } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../app/appSlice';
import { optionTypeAsync } from '../app/patientSlice';
import { getFaciltiestCustomerAsyn, setCustomerFacilities } from '../app/usageAnalyticsReportSlice';
import { getWoundDashBoardOverview, getWoundDashBoardWoundcount, setWoundReportPayload,setOverViewData,setWoundCountData,setWoundAssessmentData,setWoundReportLastVal, getWoundDashBoardAssessment } from '../app/woundReportSlice';
import { Report } from '../components/core/WoundReport/report';
import { Button, CheckBox, DateTimePicker, Form, MultiSelect, Organizational, SelectBox, TextField } from '../components/primary';
import { Tab as TabComponent } from '../components/secondary';
import { Header } from '../components/secondary/Header';
import Spreadsheet from '../static/images/ExportSpredsheet.svg';
import rightArrow from '../static/images/right_arrow.svg';
import { convertnewDateToDate, getFacilityLabel } from '../utils';
import style from './woundReport.module.scss';
import { DASHBOARD_WOUNDASSESSMENT, DASHBOARD_WOUNDASSESSMENT_OVERVIEW, LIST_VIEW_LIMIT } from '../constants';

export const WoundReport = ({ history }) => {
    const [firstTime, setFirstTime] = useState('')
    const [tempPayload, setTempPayload] = useState("")
    const dispatch = useDispatch();
    const woundReportPayload = useSelector(state => state.woundreport.woundReportPayload);
    // /setWoundReportLastVal
    const userDetails = useSelector(state => state.user);
    useEffect(() => {
        if (userDetails?.role !== "MedlineAdministrator"&&userDetails?.customerSettings?.ne1Wms === 0) {  
            history.push('/learnMore'); 
          }
        if(userDetails && userDetails.customerSettings &&userDetails?.customerSettings?.ne1Wms === 0){
          if(userDetails?.role==="ExternalStandardUser" || userDetails?.role==="ExternalAdministrator" || userDetails.role === "ExternalNE1WoundExpert" || userDetails.role === "ExternalNE1Review" || userDetails.role === "ExternalNE1Assesment"){
            history.push('/learnMore')
          }
        
        }
        }, [userDetails])
    const [searchCustomerNumber, setSearchCustomerNumber] = useState(woundReportPayload && woundReportPayload.tempWoundCustomer ? woundReportPayload.tempWoundCustomer : userDetails?.customerNumber);
    useEffect(() => {
        dispatch(setOverViewData({}));
        dispatch(setWoundCountData({}));
        dispatch(setWoundAssessmentData({}))
        setTempPayload('')
        dispatch(setCustomerFacilities({}))
        dispatch(getFaciltiestCustomerAsyn(woundReportPayload && woundReportPayload.tempWoundCustomer ? woundReportPayload.tempWoundCustomer : userDetails?.customerNumber));
        setFirstTime(true)
        setShowApplyState(false);
        dispatch(setWoundReportPayload({}));
        let wountCountPayload = { ...woundReportPayload, optionSelected:woundReportPayload?.optionSelected?woundReportPayload.optionSelected: "Patients with Wound"}
        dispatch(setWoundReportPayload(wountCountPayload));
        setonselectOrgz([])
    }, [])

  

    /* Use hook form */
    const { register, handleSubmit, control, errors, reset, setError, watch } = useForm();

    let dateRangeRef1 = useRef();
    let dateRangeRef2 = useRef();

    const [startDate, setStartDate] = useState(new Date());
    const [toDateRes, setToDateRes] = useState('');
    const [showApplyState, setShowApplyState] = useState(false);
    const [onselectOrgz, setonselectOrgz] = useState([]);
    const [selectedDivOption, setSelectedDivOption] = useState((woundReportPayload?.optionSelected) ? woundReportPayload?.optionSelected : "Patients with Wound")
    const [woundProgressValue, setWoundProgressValue] = useState(woundReportPayload?.woundProgress?woundReportPayload?.woundProgress:"new");
    const [tabIndex, setTabIndex] = useState(woundReportPayload?.tabIndex? woundReportPayload?.tabIndex:0);
    const [selectedWoundValue, setSelectedWoundValue] = useState(woundReportPayload && woundReportPayload.woundType ? woundReportPayload.woundType : []);
    
    const [resetFacility, setResetFacility] = useState(false);
    const [selectedFacilities, setSelectedFacilities] = useState([])
    const [submitState, setSubmitState] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [adminAccess, setAdminAccess] = useState(false);
    const [orgError, setOrgError] = useState(false);
   
    useEffect(() => {
        if (userDetails && userDetails.role === "MedlineAdministrator" || userDetails.role === "MedlineSupport") {
            setAdminAccess(true);
        }
    }, [userDetails])
    const woundTypeRef = useRef();
    const customFromDatePickerChange = (e) => {
        const fromDates = new Date(e).getTime()
        const toDates = new Date(document.getElementById('datePicker2').value).getTime()
        setToDateRes(new Date(e))
        setTimeout(() => {
            if (fromDates > toDates) {
                document.getElementById('datePicker2').value = ''
            }
        }, 500)
        setShowApplyState(true);
    }
    const handleOnBlurFrom = ({ target: { value } }) => {
        const fromDates = new Date(value).getTime()
        const toDates = new Date(document.getElementById('datePicker2').value).getTime()
        setToDateRes(new Date(value))
        setTimeout(() => {
            if (fromDates > toDates) {
                document.getElementById('datePicker2').value = ''
            }
        }, 500)
    };
    const handleOnBlurTO = ({ target: { value } }) => {
        const fromDates = new Date(document.getElementById('datePicker1').value).getTime()
        const toDates = new Date(value).getTime()
        setTimeout(() => {
            if (fromDates > toDates) {
                document.getElementById('datePicker2').value = ''
                setToDateRes('')
            }
        }, 500)
    };
    const customToDatePickerChange = (e) => {
        setShowApplyState(true);
        const fromDates = new Date(document.getElementById('datePicker1').value).getTime()
        const toDates = new Date(e).getTime()
        setTimeout(() => {
            if (fromDates > toDates) {
                document.getElementById('datePicker1').value = ''
                setToDateRes('')
            }
        }, 500)
    }


    const getFacilities = useSelector(state => state.usageAnalyticsReport.customerFacilities);
    let optionData = [];
    const soldToFacilities = getFacilities?.data?.facilities?.filter((f) => f.accountGroup === '0001')
    soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
        const shipToFacilities = getFacilities.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
         if(shipToFacilities.length===0){
            optionData.push({
                    cat: getFacilityLabel(soldToFacility),
                    key: getFacilityLabel(soldToFacility),
                    facilityNumber: soldToFacility.facilityNumber,
                    soldToFacility: 'soldToFacility'
                })
               }
        shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(shipToFacility),
                facilityNumber: shipToFacility.facilityNumber,
                shipToFacilities: 'shipToFacilities'
            })
        })
    })

    useEffect(() => {
        let fac = ""
        if (getFacilities?.errorMessage) {
            fac = "Primary group code doesn’t match our record"
        }
        setErrorMsg(fac)
    }, [getFacilities])

    const getCustomerNumber = (value) => {
        if (value === '') {
            setErrorMsg("Please enter primary group code");
        } else {
            setonselectOrgz([])
            // API to check wheather customer exists or not
            setShowApplyState(true);
            dispatch(getFaciltiestCustomerAsyn(value));
            dispatch(setWoundReportPayload({...woundReportPayload,onselectOrgz:[]}))
        }
    }
    const onSelectOrg = (selectedList) => {
        if (optionData && selectedList && optionData?.length !== selectedList?.length) {
            setShowApplyState(true);
            setResetFacility(false);
        }
        if (setSelectedFacilities) {
            setOrgError(false)
            let facilitykey = [];
            selectedList && selectedList?.map((item) => { //NOSONAR
                let facility = item.key.split("|")[0].trim();
                facilitykey = [...facilitykey, facility];
            })//NOSONAR
            setSelectedFacilities(facilitykey)
        }
        
       
        if(selectedList.length===0){
            setonselectOrgz([])
        }else{
            setonselectOrgz([...selectedList])
        }
    }

    useEffect(() => {
        dispatch(optionTypeAsync('insurancepayer'));
        dispatch(optionTypeAsync('woundType'));

    }, [])

    const insurancePayerData = useSelector(state => state.patient.insurancePayer);
    const woundTypeData = useSelector(state => state.patient.woundType);

    const handleWoundChange = (e) => {
        setShowApplyState(true);
        if (e && e?.length > 0) {
            let arr = [];
            e.map((data) => {
                if (data.key !== '') {
                    arr.push(data.key)
                }

            })
            setSelectedWoundValue(arr)
        }else{
            setSelectedWoundValue([])
        }
    }


    const handlePayorChange = (e) => {
        setShowApplyState(true);
    }
    const onClickCheckBox = (e) => {
        setShowApplyState(true);
    }

    // reset fields.
    const resetData = () => {
        setonselectOrgz([])
         dispatch(setCustomerFacilities({}))
         dispatch(setWoundReportPayload({}))
         reset({ woundType: '', payorType: '', isDischargedPatient: '' });
         woundTypeRef.current.resetSelectedValues([]);
         setSelectedWoundValue('');
         setResetFacility(true);
         setSubmitState(false);
         setTempPayload("");
         setSelectedDivOption('Patients with Wound');
         setTabIndex(0)
         setWoundProgressValue('new');
         dispatch(getFaciltiestCustomerAsyn(userDetails?.customerNumber));
        setTimeout(() => {
            setShowApplyState(true);
            setFirstTime(true)
            //pageOnloadAPI();
        }, 500)

    }

    const optionSelected = (e) => {
        setTabIndex( woundReportPayload?.tabIndex? woundReportPayload?.tabIndex:0)
        setWoundProgressValue(woundReportPayload?.woundProgress?woundReportPayload?.woundProgress:'new');
        setSelectedDivOption(e);
        /*let wountCountPayload = { ...woundReportPayload, optionSelected: e }
         dispatch(setWoundReportPayload(wountCountPayload))*/
         let wountCountPayload = { ...tempPayload, optionSelected: e }
         dispatch(setWoundReportPayload({ ...woundReportPayload, optionSelected: e }))
        setTempPayload(wountCountPayload)
        dispatch(getWoundDashBoardWoundcount(wountCountPayload))
        setTempPayload(wountCountPayload)
    }

    const pageOnloadAPI = () => {        
        let facilitykey = [];
        optionData && Array.isArray(optionData) && optionData?.map((item) => { //NOSONAR
            let facility = item.key.split("|")[0].trim();
            facilitykey = [...facilitykey, facility];
        })//NOSONAR

        const payload = {
            fromDate: convertnewDateToDate(new Date(document.getElementById('datePicker1').value)),
            toDate: convertnewDateToDate(new Date(document.getElementById('datePicker2').value)),
            facility: facilitykey,
            woundType: "",
            payor: "",
            isDischargedPatient: 0
        }
       
      
        if (optionData.length > 0) {
            if (woundReportPayload && woundReportPayload?.facility && woundReportPayload?.facility.length !== 0) {
                dispatch(getWoundDashBoardOverview(woundReportPayload));
                setTempPayload(woundReportPayload)
                let wountCountPayload = { ...woundReportPayload, optionSelected: woundReportPayload.optionSelected }
                dispatch(getWoundDashBoardWoundcount(wountCountPayload))
                setShowApplyState(true);
            } else {
                if(payload.facility&&payload.facility.length>0){
                    dispatch(getWoundDashBoardOverview(payload));
                setTempPayload(payload)
              //  let wountCountPayload = { ...payload, optionSelected: selectedDivOption, }
                let wountCountPayload = { ...payload, optionSelected : woundReportPayload?.optionSelected?woundReportPayload.optionSelected:"Patients with Wound",onselectOrgz:optionData }
                dispatch(getWoundDashBoardWoundcount(wountCountPayload))
                dispatch(setWoundReportPayload(wountCountPayload));
                setTempPayload(wountCountPayload)
                }
                
            }
            if (document.getElementById('datePicker1').value) {
                setToDateRes(new Date(document.getElementById('datePicker1').value))
            }
           
        }

    }
    
    

    useEffect(() => {
            if (firstTime&&getFacilities?.data?.facilities.length>0&&document.getElementById('datePicker1')&&document.getElementById('datePicker2')) {
            setTimeout(() => {
                setFirstTime(false)
                pageOnloadAPI();
                
            }, 100)
        }
    }, [firstTime,getFacilities])



    const overViewData = useSelector(state => state.woundreport.overViewData);

    const woundCountData = useSelector(state => state.woundreport.woundCountData);


    const tabList = [
        {
            name: <> New <span>{woundCountData?.new} </span></>,
            content: <Report woundProgressValue={woundProgressValue} tempPayload={tempPayload} selectedDivOption={selectedDivOption} />,
            className: style.tab_none
        },
        {
            name: <> Improving <span>{woundCountData?.improving}</span></>,
            content: <Report woundProgressValue={woundProgressValue} tempPayload={tempPayload} selectedDivOption={selectedDivOption} />,
            className: style.tab_none
        },
        {
            name: <> Stable <span>{woundCountData?.stable}</span></>,
            content: <Report woundProgressValue={woundProgressValue} tempPayload={tempPayload} selectedDivOption={selectedDivOption} />,
            className: style.tab_none
        },
        {
            name: <> Stalled <span>{woundCountData?.stalled}</span></>,
            content: <Report woundProgressValue={woundProgressValue} tempPayload={tempPayload} selectedDivOption={selectedDivOption} />,
            className: style.tab_none
        },
        {
            name: <> Deteriorating <span>{woundCountData?.detoriating}</span></>,
            content: <Report woundProgressValue={woundProgressValue} tempPayload={tempPayload} selectedDivOption={selectedDivOption} />,
            className: style.tab_none
        },
        {
            name: <>Monitoring <span> {woundCountData?.monitoring}</span></>,
            content: <Report woundProgressValue={woundProgressValue} tempPayload={tempPayload} selectedDivOption={selectedDivOption} />,
            className: style.tab_none
        },
        {
            name: <> Resolved <span>{woundCountData?.resolve}</span></>,
            content: <Report woundProgressValue={woundProgressValue} tempPayload={tempPayload} selectedDivOption={selectedDivOption} />,
            className: style.tab_none
        },


    ];

    

    const onSelectedTab = (e) => {
        const selectedTab =  {0:'new',1:'improving',2:'stable',3:'stalled',4:'deteriorating',5:'monitoring',6:'resolved'}
        setTabIndex(e);
        setWoundProgressValue(selectedTab[e]);
        dispatch(setWoundReportPayload({...woundReportPayload,tabIndex:e,woundProgress:selectedTab[e]}))
        
    }


    const onSubmit = (data) => {
        setSelectedDivOption(woundReportPayload?.optionSelected?woundReportPayload?.optionSelected:'Patients with Wound');
        setWoundProgressValue(woundReportPayload?.woundProgress?woundReportPayload?.woundProgress:'new')
        setErrorMsg("");
        if (selectedFacilities.length === 0) {
            setOrgError(true);
        } else {
            setOrgError(false);
        }
        setSubmitState(true)

        let payload = {
            fromDate: convertnewDateToDate(data.datePicker1),
            toDate: convertnewDateToDate(data.datePicker2),
            facility: selectedFacilities,
            woundType: selectedWoundValue,
            payor: data.payorType,
            isDischargedPatient: (data.isDischargedPatient === "Include Discharged Patients") ? 1 : 0
        }
        const fromDates = new Date(document.getElementById('datePicker1').value).getTime()
        const toDates = new Date(document.getElementById('datePicker2').value).getTime()
        setToDateRes(new Date(document.getElementById('datePicker1').value))
        setTimeout(() => {
            if (fromDates > toDates) {
                document.getElementById('datePicker2').value = ''
            } else {
                if (selectedFacilities.length >0) {
                dispatch(getWoundDashBoardOverview(payload))
               
                let wountCountPayload = { ...payload, optionSelected: (selectedDivOption !== "") ? selectedDivOption : "Patients with Wound",onselectOrgz:onselectOrgz }

                dispatch(getWoundDashBoardWoundcount(wountCountPayload))
                setTempPayload(wountCountPayload)
                let tempWoundPayload = wountCountPayload;
                if (document.getElementById('customerNumber')) {
                    tempWoundPayload = { ...tempWoundPayload, tempWoundCustomer: document.getElementById('customerNumber').value }
                }
                dispatch(setWoundReportPayload(tempWoundPayload))
            }
             
               
            }
        }, 10)


    }

    const pdfPage = () => {
        dispatch(setLoading(true))
        history.push('/woundReport/pdfdownload');
    }

    const onClickOverview = (value) => {
        let getClickPath = value   
        optionSelected('Patients with Wound');  
        const payload = {
            fromDate: convertnewDateToDate(new Date(document.getElementById('datePicker1').value)),
            toDate: convertnewDateToDate(new Date(document.getElementById('datePicker2').value)),
            facility: woundReportPayload?.facility,
            woundType: "",
            payor: "",
            isDischargedPatient: 0,
            optionSelected: woundReportPayload?.optionSelected?woundReportPayload.optionSelected:"Patients with Wound",
            onselectOrgz: woundReportPayload?.onselectOrgz,
            tempWoundCustomer: "",
            woundProgress: "new" 
        }       
        if(getClickPath==="patientwithactivewounds"){
            if (optionData.length > 0) {
                let listPayLoad = {};
                listPayLoad['url'] = `${DASHBOARD_WOUNDASSESSMENT}`
                listPayLoad['method'] = 'Post'
                listPayLoad['payload'] = payload
                listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT }
                if (woundReportPayload && woundReportPayload?.facility && woundReportPayload?.facility.length !== 0) {
                    dispatch(getWoundDashBoardAssessment(listPayLoad))
                }
            }
        }
        if(getClickPath==="activewoundsnotresolved"){
            if (optionData.length > 0) {
                let listPayLoad = {};
                listPayLoad['url'] = `${DASHBOARD_WOUNDASSESSMENT_OVERVIEW}/${getClickPath}`
                listPayLoad['method'] = 'Post'
                listPayLoad['payload'] = payload
                listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT }
                if (woundReportPayload && woundReportPayload?.facility && woundReportPayload?.facility.length !== 0) {
                    dispatch(getWoundDashBoardAssessment(listPayLoad))
                }
            }
        }
    }

    const patientWithActiveWounds = () => {
        dispatch(setWoundAssessmentData({}))
        onClickOverview('patientwithactivewounds');        
        dispatch(setLoading(false))
        document.getElementById('overviewLoadingTable').scrollIntoView();
    }
    
    const activeWoundsNotResolved = () => {
        dispatch(setWoundAssessmentData({}))
        onClickOverview('activewoundsnotresolved');
        dispatch(setLoading(false))
            history.push({
            pathname: 'woundReport/activewoundsnotresolved',
            state: true
        });
    }

    return (
        <>
            <div className="right_block">
                <Header reportHeading={"Wounds Dashboard"} />
                <div className={`col ${style.wound_dashboard} mt-0`}>
                    <Form className={style.wound_form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={`col-8 ${style.col_spec}`}>
                            <label className={`col-5 pl-0 ${style.label_spec}`}>Date Range:</label>
                            <DateTimePicker
                                id={"datePicker1"}
                                name={"datePicker1"}
                                control={control}
                                classes={{
                                    date_picker: style.wound_input,
                                    dateColumn: "col-12 p-0",
                                    column: `col m-0 ${style.col_spec}`,
                                }}
                                selected={startDate}
                                customOnChange={customFromDatePickerChange}
                                // defaultValue={(woundReportPayload && woundReportPayload.fromDate) ? new Date(woundReportPayload.fromDate) : subDays(new Date(startDate), 30)}
                                defaultValue={(woundReportPayload && woundReportPayload.fromDate) ? new Date(woundReportPayload.fromDate) : subYears(new Date(startDate), 1)}
                                displayIcon={true}
                                validator={register()}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dateRef={dateRangeRef1}
                                errors={errors}
                                maxDate={new Date()}
                                onBlur={handleOnBlurFrom}
                            />
                            <DateTimePicker
                                id={"datePicker2"}
                                name={"datePicker2"}
                                control={control}
                                classes={{
                                    date_picker: style.wound_input,
                                    dateColumn: "col-12 p-0",
                                    column: `col m-0 pr-0 ${style.col_spec}`,
                                }}
                                defaultValue={(woundReportPayload && woundReportPayload.toDate) ? new Date(woundReportPayload.toDate) : new Date()}
                                customOnChange={customToDatePickerChange}
                                displayIcon={true}
                                validator={register()}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dateRef={dateRangeRef2}
                                minDate={toDateRes ? toDateRes : ''}
                                errors={errors}
                                maxDate={new Date()}
                                onBlur={handleOnBlurTO}
                            />
                        </div>
                        <div className={`col-4 ${style.col_spec}`}></div>
                        {adminAccess &&
                            <>
                                <TextField
                                    id={"customerNumber"}
                                    name={"customerNumber"}
                                    label={"Primary Group Code:"}
                                    classes={{
                                        column: `col-8 ${style.col_spec}`,
                                        labelClass: `col-5 col-form-label ${style.label_spec} pl-0`,
                                        inputColumn: `col-7 p-0`,
                                        inputClassName: `${style.wound_input}  customerNum`
                                    }}
                                    type="text"
                                    errors={errors}
                                    validator={register}
                                    customError={errorMsg ? errorMsg : ''}
                                    defaultValue={(woundReportPayload && woundReportPayload.tempWoundCustomer) ? woundReportPayload.tempWoundCustomer : ''}
                                    required={true}
                                />
                                <span className={`col-3 col-form-label ${style.form_span} getorg `} onClick={(e) => getCustomerNumber(document.getElementById('customerNumber').value)}>
                                    Search <img src={rightArrow} alt="" className={`${style.img_left}`} />
                                </span>
                            </>
                        }
                        {optionData && Array.isArray(optionData) && optionData.length === 0 &&
                            <Organizational
                                id={"facility"}
                                name={"facility"}
                                label={`Facility/Location:`}
                                classes={{
                                    column: `col-8 ${style.col_spec}`,
                                    inputClassName: style.wound_select,
                                    labelClassName: `col-5 ${style.label_spec} pl-0`,
                                    inputColumn: `col-7 p-0`
                                }}
                                optionValue={optionData}
                                groupBy="cat"
                                displayValue="key"
                                defaultValue={Array.isArray(onselectOrgz)?onselectOrgz:optionData}
                                selectAll={true}
                                onSelect={(val) => onSelectOrg(val)}
                                resetDefault={resetFacility}
                                reportView={true}
                                customError={orgError}
                                required={true}    
                                               
                            />
                        }
                        {optionData && Array.isArray(optionData) && optionData.length !== 0 &&
                            <Organizational
                                id={"facility"}
                                name={"facility"}
                                label={`Facility/Location:`}
                                classes={{
                                    column: `col-8 ${style.col_spec}`,
                                    inputClassName: style.wound_select,
                                    labelClassName: `col-5 ${style.label_spec} pl-0`,
                                    inputColumn: `col-7 p-0`
                                }}
                                optionValue={optionData}
                                
                                groupBy="cat"
                                displayValue="key"
                               // defaultValue={optionData}
                                defaultValue={Array.isArray(woundReportPayload?.onselectOrgz)&&woundReportPayload?.onselectOrgz.length>0?woundReportPayload?.onselectOrgz:Array.isArray(onselectOrgz)&&onselectOrgz.length>0?onselectOrgz:optionData}
                                selectAll={true}
                                onSelect={(val) => onSelectOrg(val)}
                                resetDefault={resetFacility}
                                reportView={true}
                                customError={orgError}
                                required={true}
                            />
                        }
                        <div className={`col-4 ${style.col_spec}`}></div>
                        <MultiSelect
                            id={"woundType"}
                            name={"woundType"}
                            label={"Wound Types:"}
                            classes={{
                                column: `col-8 ${style.col_spec}`,
                                inputClassName: style.wound_select,
                                labelClassName: `col-5 ${style.label_spec} pl-0`,
                                inputColumn: `col-7 p-0 ${style.select_wrapper}`,
                            }}
                            optionValue={woundTypeData}
                            validator={(e) => {
                                register({ required: true })
                                woundTypeRef.current = e
                            }}
                            placeholder="Select"
                            showCheckbox={true}
                            displayValue="value"
                            onSelect={handleWoundChange}
                            onRemove={handleWoundChange}
                            labelSubText={'Select all that apply'}
                            defaultValue={(woundReportPayload && woundReportPayload.woundType) ? woundReportPayload.woundType : []}

                        />
                        <div className={`col-4 ${style.col_spec}`}></div>
                        <SelectBox
                            id={"payorType"}
                            name={"payorType"}
                            label={"Payor Type:"}
                            classes={{
                                column: `col-8 ${style.col_spec}`,
                                inputClassName: style.wound_select,
                                labelClassName: `col-5 ${style.label_spec} pl-0`,
                                inputColumn: `col-7 p-0 ${style.select_payortype}`
                            }}
                            optionValue={insurancePayerData}
                            validator={register}
                            placeholder="Select"
                            errors={errors}
                            onChange={e => handlePayorChange(e.currentTarget.value)}
                            defaultValue={(woundReportPayload && woundReportPayload.payor) ? woundReportPayload.payor : ''}
                        />

                        <div className={`col-4 ${style.col_spec}`}></div>

                        <div className={`col-8 ${style.col_spec} p-0`}>
                            <div className="col-6 offset-5 p-0 mb-0">
                                <CheckBox
                                    classes={{ labelClassName: style.custom_control_label }}
                                    label={"Include Discharged Patients"}
                                    type={"checkbox"}
                                    name={"isDischargedPatient"}
                                    id={"checkBoxId"}
                                    validator={register}
                                    onClick={e => onClickCheckBox(e.currentTarget.value)}
                                    woundReport={true}
                                    defaultChecked={(woundReportPayload && woundReportPayload.isDischargedPatient === 1) ? true : ''}
                                />
                            </div>
                        </div>
                        <div className={`col-4 ${style.col_spec}`}></div>
                        {showApplyState &&
                            <div className={`col-8 ${style.col_spec}`}>
                                <label className={`col-5 ${style.label_spec}`}></label>
                                <Button
                                    classes={{ className: `btn ${style.btn_custom} ${style.btn_small} mr-4` }}
                                    type="submit"
                                >
                                    Apply
                                </Button>
                                <Button
                                    classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel} ` }}
                                    onClick={resetData}
                                    type={'button'}
                                >
                                    Reset
                                </Button>
                            </div>
                        }

                    </Form>
                    <>
                        <div className={style.overview_blk}>
                            <div className={style.overview_top}>
                                <p className={style.overview_title}>Overview</p>
                                <span className={style.down_spread} onClick={pdfPage}><img src={Spreadsheet} alt="" />
                                    <label> Download as PDF </label>
                                </span>
                            </div>
                            <div className={style.overview_btm}>
                                {/* <p><label>Patients with wounds (active & resolved) / Patients with active wounds</label><span className={`${style.commonCusrsor}`} onClick={()=>patientWithWounds()}>{overViewData?.patientCount? overViewData?.patientCount:""}</span>{overViewData?.patientWithWound?.count && ` / `}<span className={`${style.commonCusrsor}`} style={{marginLeft:'-30px'}} onClick={()=>patientWithActiveWounds()}>{overViewData?.patientWithWound?.count ? overViewData?.patientWithWound?.count : ""}</span></p> */}
                                <p><label>Patients with active wounds</label><span className={`${style.commonCusrsor}`} onClick={()=>patientWithActiveWounds()}>{overViewData?.patientWithWound?.count ? overViewData?.patientWithWound?.count : ""}</span></p>
                                <p><label>Active wounds that are not resolved</label><span className={`${style.commonCusrsor}`} onClick={()=>activeWoundsNotResolved()}>{overViewData?.activeWound}</span></p>
                                <p><label>Average number of wounds per patient</label><span>{overViewData?.avgWoundPerPatient}</span></p>
                                <p><label>Average number of assessments per wound</label><span>{overViewData?.avgAssessmentPerWound}</span></p>
                                <p><label>Healing rate</label><span>{`${overViewData&& overViewData.healingRate&&overViewData.healingRate!==false&&overViewData.healingRate!==undefined?overViewData.healingRate+'%':''}`}</span></p>

                            </div>
                            <div className={style.overview_score}>
                                <div className={`crd_opt_1 ${style.overview_percentage} ${(selectedDivOption === "Patients with Wound") ? style.active : ''}`} onClick={() => optionSelected("Patients with Wound")}>
                                    <label className={style.dash_top}>Patients with Wounds</label>
                                    <p className={style.dash_btm}>
                                        <span>{overViewData?.patientWithWound?.count}</span>
                                        <span>{overViewData?.patientWithWound?.percent}</span>
                                    </p>
                                </div>
                                <div className={`crd_opt_2 ${style.overview_percentage} ${(selectedDivOption === "Pressure Injury POA") ? style.active : ''}`} onClick={() => optionSelected("Pressure Injury POA")}>
                                    <label className={style.dash_top}>Pressure Injury
                                        <span className={style.dash_poa}>POA</span>
                                    </label>

                                    <p className={style.dash_btm}>
                                        <span>{overViewData?.piPOA?.count}</span>
                                        <span>{overViewData?.piPOA?.percent}</span>
                                    </p>
                                </div>
                                <div className={`crd_opt_3 ${style.overview_percentage} ${(selectedDivOption === "Pressure Injury Non-POA (Stage 1, 2, DTPI, MMPI)") ? style.active : ''}`} onClick={() => optionSelected("Pressure Injury Non-POA (Stage 1, 2, DTPI, MMPI)")}>
                                    <label className={style.dash_top}>Pressure Injury
                                        <span className={style.dash_nonpoa}>Non-POA</span>
                                        <span className={style.dash_stage}>(Stage 1, 2, DTPI, MMPI)</span>
                                    </label>

                                    <p className={style.dash_btm}>
                                        <span>{overViewData?.piNonPOAStageMMPI?.count}</span>
                                        <span>{overViewData?.piNonPOAStageMMPI?.percent}</span>
                                    </p>
                                </div>
                                <div className={`crd_opt_4 ${style.overview_percentage} ${(selectedDivOption === "Pressure Injury Non-POA (Stage 3, 4, Unstageable)") ? style.active : ''}`} onClick={() => optionSelected("Pressure Injury Non-POA (Stage 3, 4, Unstageable)")}>
                                    <label className={style.dash_top}>Pressure Injury
                                        <span className={`${style.dash_nonpoa} ${style.dash_color}`}>Non-POA</span>
                                        <span className={`${style.dash_stage} ${style.dash_color}`}>(Stage 3, 4, Unstageable)</span>
                                    </label>

                                    <p className={style.dash_btm}>
                                        <span>{overViewData?.piNonPOAUnStageable?.count}</span>
                                        <span>{overViewData?.piNonPOAUnStageable?.percent}</span>
                                    </p>
                                </div>
                                <div className={`crd_opt_5 ${style.overview_percentage} ${(selectedDivOption === "Other") ? style.active : ''}`} onClick={() => optionSelected("Other")}>
                                    <label class={style.dash_top}>Others</label>

                                    <p class={style.dash_btm}>
                                        <span>{overViewData?.other?.count}</span>
                                        <span>{overViewData?.other?.percent}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* {woundCountData && woundCountData?.new!==0 && woundCountData?.improving!==0 && woundCountData?.stable!==0 && woundCountData?.stalled!==0 && woundCountData?.detoriating!==0 && woundCountData?.resolve!==0 ? */}
                            <TabComponent onSelect={onSelectedTab} defaultIndex={tabIndex?tabIndex:0} selectedIndex={tabIndex} tabList={tabList} woundReportView={true} workListView={true} />
                            {/* :
                                <div className={style.no_matching_result}>Oops there are no matching results, please update your filters</div>
                                }     */}
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}
