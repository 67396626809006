import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CompletedAssessment, NewAdmission, NewWounds, OtherAssessments, PIAssessment, WoundExpert, WoundPhotos } from '../components/core/WorkList';
import { Tab as TabComponent } from '../components/secondary';
import { Header } from '../components/secondary/Header';
import style from './worklist.module.scss';
import { SelectBox, TextField } from '../components/primary';
import { useForm } from 'react-hook-form';
import { getNewFacilityDataAsync, setOtherPageFacilityCheck, setLastVal,getWorkListRecordsCount,setWorkListRecordsCount } from '../app/patientSlice';
import { getFacilityLabel } from '../utils';
import { workListTabRbacChecker } from '../utils/rbac';
import rightArrow from '../static/images/right_arrow.svg';
import {
  setNewAdmissionPOAData,
  setNewAdmissionNONPOAData,
  setNewWoundsPOAData,
  setNewWoundsNONPOAData,
  setPIAssessmentPOAData,
  setPIAssessmentNONPOAData,
  setOtherAssessmentPOAData,
  setOtherAssessmentNONPOAData,
  setWoundExpertAssementPOAData,
  setWoundExpertAssementNONPOAData,
  setWoundExpertAssementDebridementData,
  setCompletedAssessmentData,
  setWoundPhotoData
} from  '../app/workListSlice';
export let WorkList = ({ dispatch, history, location }) => {
  const { register, handleSubmit, control, errors, reset, setError, watch } = useForm();
  const getFacilities = useSelector(state => state.patient.newFacilitiesData);
  const userRole = useSelector(state => state.user);
  const otherPageFacilityCheck = useSelector(state => state.patient.otherPageFacilityCheck);
  const [onselectOrgz, setonselectOrg] = useState([]);
  const [errorMsg, setErrorMsg] = useState("")
  const lastVal = useSelector(state => state.patient.lastVal)
  const [defaultTab, setDefaultTab] = useState(lastVal?.selectTab?lastVal?.selectTab:0)

  const resetWorklist=()=>{
    setFacilityNumber('')
    dispatch(setWorkListRecordsCount({}))
    dispatch(setNewAdmissionPOAData({}))
    dispatch(setNewAdmissionNONPOAData({}))
    dispatch(setNewWoundsPOAData({}))
    dispatch(setNewWoundsNONPOAData({}))
    dispatch(setPIAssessmentPOAData({}))
    dispatch(setPIAssessmentNONPOAData({}))
    dispatch(setOtherAssessmentPOAData({}))
    dispatch(setOtherAssessmentNONPOAData({}))
    dispatch(setWoundExpertAssementPOAData({}))
    dispatch(setWoundExpertAssementNONPOAData({}))
    dispatch(setWoundExpertAssementDebridementData({}))
    dispatch(setCompletedAssessmentData({}))
    dispatch(setWoundPhotoData({}))
  }
  useEffect(() => {
    if (userRole?.role !== "MedlineAdministrator"&&userRole?.customerSettings?.ne1Wms === 0) {  
      history.push('/learnMore'); 
    }
    if(userRole && userRole.customerSettings &&userRole?.customerSettings?.ne1Wms === 0){
      if(userRole?.role==="ExternalStandardUser" || userRole?.role==="ExternalAdministrator" || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment"){
        history.push('/learnMore')
      }
    }
    }, [userRole])
  useEffect(() => {
    resetWorklist()
    dispatch(getNewFacilityDataAsync({ customerNumber: lastVal?.customerNum ? lastVal.customerNum : userRole?.customerNumber },setErrorMsg));
  }, [dispatch])

  let optionData = [];
  (getFacilities?.data) && getFacilities?.data?.facilities.map((value, index) => {//NOSONAR
    optionData.push({ value: getFacilityLabel(value), key: value.facilityNumber })
  })//NOSONAR


 
  // let facilityNumber = otherPageFacilityCheck ? otherPageFacilityCheck : optionData[0]?.key ? optionData[0].key : ''
  const [facilityNumber, setFacilityNumber] = useState((otherPageFacilityCheck) ? otherPageFacilityCheck : optionData[0]?.key ? optionData[0].key : '');

  useEffect(() => {
    if ((getFacilities?.data) && getFacilities?.data?.facilities.length === 0) {
      dispatch(setOtherPageFacilityCheck(''))
      setFacilityNumber('');
      dispatch(setLastVal({
        customerNum  : lastVal?.cutomerNum ? lastVal.customerNum : document?.getElementById('customerNumber')?.value,
        facilitylocation : '',
        selectTab:defaultTab
      }))
    }
    if ((getFacilities?.data) && getFacilities?.data?.facilities.length !== 0) {
     
      if (document.getElementById('facility')&&document.getElementById('facility').value) {
        if(document.getElementById('facility').value){
          dispatch(setOtherPageFacilityCheck(document.getElementById('facility').value))
          setFacilityNumber(document.getElementById('facility').value);
        }
      }else{
        if(document.getElementById('facility')){
          document.getElementById('facility').value=optionData[0]?.key ? optionData[0].key:facilityNumber
        }
        dispatch(setOtherPageFacilityCheck(optionData[0]?.key ? optionData[0].key:facilityNumber))
        //setFacilityNumber(optionData[0]?.key ? optionData[0].key:facilityNumber);
      }
    }
  }, [getFacilities, facilityNumber,otherPageFacilityCheck])

  /* Tab list */
  const tabList = [
    {
     
      name: <> Patient Submitted</>,
      content: <WoundPhotos facilityNumber={facilityNumber} />
    },
    {
      name: 'New Admissions',
      content: <NewAdmission facilityNumber={facilityNumber} />
    },
    {
      name: "New Wounds",
      content: <NewWounds facilityNumber={facilityNumber} />
    },
    {
      name: 'PI Assessments',
      content: <PIAssessment facilityNumber={facilityNumber} />
    },
    {
      name: 'Other Assessments',
      content: <OtherAssessments facilityNumber={facilityNumber} />
    },
    {
      name: 'Wound Expert',
      content: <WoundExpert facilityNumber={facilityNumber} />
    },
    {
      name: 'Completed Assessments',
      content: <CompletedAssessment facilityNumber={facilityNumber} />
    },
  ];

  const handleFacility = (e) => {
    resetWorklist()
    setFacilityNumber(e.target.value);
    dispatch(setOtherPageFacilityCheck(e.target.value))
    dispatch(setLastVal({
      customerNum  : lastVal?.cutomerNum ? lastVal.customerNum : document?.getElementById('customerNumber')?.value,
      facilitylocation : e.target.value,
      selectTab:defaultTab
    }))
    setFacilityNumber(e.target.value);

  }

  const [showClearX, setShowClearX] = useState(lastVal?.customerNum?true:false)
   
  const getShowClearX = (e) => {
 if (e?.target?.value) {
   setShowClearX(true)
  // return e.target.value=e?.target?.value.replace(/(\s{2,})/g, ' ').replace(/^\s*/, '')
 }else{
   setShowClearX(false)
   dispatch(setLastVal({
    customerNum  : '',
    //facilitylocation : lastVal.facilitylocation ? optionData  : '',
    facilitylocation:'',
    selectTab:defaultTab
  }))
  resetWorklist()
  dispatch(getNewFacilityDataAsync({ customerNumber:userRole?.customerNumber },setErrorMsg));
 }
}
const getSearchValue = (event) => {
  event.preventDefault();
  if(event?.target?.value &&event.keyCode === 13){
    dispatch(setLastVal({
      customerNum  : event?.target?.value,
      //facilitylocation : lastVal.facilitylocation ? optionData  : '',
      facilitylocation:'',
      selectTab:defaultTab
    }))
    dispatch(getNewFacilityDataAsync({ customerNumber:event?.target?.value },setErrorMsg));
  }else if(event?.target?.value===undefined){
    setShowClearX(false)
    dispatch(setLastVal({
     customerNum  : '',
     //facilitylocation : lastVal.facilitylocation ? optionData  : '',
     facilitylocation:'',
     selectTab:defaultTab
   }))
   resetWorklist()
   dispatch(getNewFacilityDataAsync({ customerNumber:userRole?.customerNumber },setErrorMsg));
  }
  
}
  const getCustomerNumber = (value) => {
    //resetWorklist()
    if (value) {
      setonselectOrg([])
      setErrorMsg('')
      dispatch(getNewFacilityDataAsync({ customerNumber: value },setErrorMsg));
      dispatch(setLastVal({
        customerNum  : value,
        //facilitylocation : lastVal.facilitylocation ? optionData  : '',
        facilitylocation:'',
        selectTab:defaultTab
      }))
    }

  }

  useEffect(() => {

    if (otherPageFacilityCheck !== '')
      dispatch(setOtherPageFacilityCheck(''));
    if(facilityNumber===''){
      resetWorklist()
    }
    
  }, [dispatch,facilityNumber])
 
  const onSelectTab = (e) => {
   setDefaultTab(e)
   const setLast={...lastVal,selectTab: e }
   dispatch(setLastVal(setLast))

  }
  return (
    <>
      <div className={`${style.main_div}`}>
        <Header workListView={true} />
        {userRole.role === 'MedlineAdministrator' || userRole.role === 'MedlineSupport' ?
          <div className='d-flex'>
            <TextField
              id={"customerNumber"}
              name={"customerNumber"}
              label={"Primary Group Code:"}
              classes={{
                column: `col-6 p-0 ${style.col_spec} mt-0`,
                labelClass: `col-4 col-form-label pl-0 ${style.label_spec}`,
                inputColumn: `col-8 p-0`,
                inputClassName: `${style.wound_input}  customerNum`
              }}
              type="text"
              validator={register()}
              errors={errors}
              // onChange={(e) => getCustomerNumber(e.target.value)}
              customError={errorMsg ? errorMsg : ''}
             
              defaultValue  = {lastVal?.customerNum ? lastVal.customerNum : ''}
              onChange={getShowClearX}
              showClearX={showClearX}
              setShowClearX={setShowClearX}
              getSearchValue={getSearchValue}
              onKeyUp={getSearchValue}
            />
            <div className="mt-2">
              <span className={`mt-1 ${style.form_span}`} onClick={(e) => getCustomerNumber(document.getElementById('customerNumber').value)

              }>
                Search <img src={rightArrow} alt="" className={`${style.img_left}`} />
              </span>
            </div>
            <br />
          </div> : ''}

        <div className='d-flex'>
          {getFacilities?.data?.facilities?.length > 1 ?
            <SelectBox
              id={"facility"}
              name={"facilityFilter"}
              label={"Facility/Location:"}
              classes={{ column: `col-6 p-0 ${style.col_spec} mt-0`, inputClassName: style.wound_select, labelClassName: `col-4 col-form-label pl-0 ${style.label_spec}`, inputColumn: `col-12 p-0 ${style.select_wrapper}` }}
              optionValue={optionData}
              defaultValue={lastVal?.facilitylocation ? lastVal.facilitylocation : ''}
              required={true}
              onChange={(e) => handleFacility(e)}
            />
            : getFacilities?.data?.facilities?.length === 1 ?
              <div className={`col-8 pr-0 ${style.col_spec} mt-0`}>
                <label className={`col-3 col-form-label pl-0 ${style.label_spec}`}>Facility/Location:</label>
                <div className="col-9 p-0">
                  <span className={`col-form-label pl-2 pr-2 ${style.select_single}`}>
                  {optionData[0]?.key&&<input type="hidden"  id={"facility"} name={"facilityFilter"}  defaultValue={optionData[0]?.key ? optionData[0].key : ''} />}
                    {optionData[0].value}
                  </span>
                </div>
              </div>

              : ''}
        </div>
        <div>
          <TabComponent tabList={workListTabRbacChecker(tabList, userRole.role)} 
           defaultIndex = {defaultTab}
           workListView={true}
           onSelect={onSelectTab}
          // woundReportView={true}
           />
        </div>
      </div>
    </>
  )
}

WorkList.propTypes = {
  dispatch: PropTypes.func,
};

WorkList = connect()(withRouter(WorkList));
