import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import {getWoundDashBoardAssessment, setWoundAssessmentData} from "../../../app/woundReportSlice";
import { DataTable, Pagination } from "../../secondary";
import { Header } from "../../secondary/Header";
import styles from "../QualityReport/overviewRowData.module.scss";
import { DASHBOARD_WOUNDASSESSMENT_OVERVIEW, LIST_VIEW_LIMIT } from '../../../constants';

export const ActiveWoundsNotResolved = ({ history }) => {
  
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.user);
    const woundReportPayload = useSelector(state => state.woundreport.woundReportPayload);

    const [adminAccess, setAdminAccess] = useState(false);
    useEffect(() => {
        if (userDetails && userDetails.role == "ExternalStandardUser") {
            setAdminAccess(true);
        }
    }, [userDetails])    

  //when onload payload passing to API
  useEffect(() => {
    const listPayLoad = {};
    listPayLoad["url"] =  `${DASHBOARD_WOUNDASSESSMENT_OVERVIEW}/activewoundsnotresolved`;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundReportPayload;
    listPayLoad["queryParam"] = { offSet: 0, limit: LIST_VIEW_LIMIT };
    if (
        woundReportPayload && woundReportPayload.facility && woundReportPayload.facility.length > 0
    ) {
      dispatch(getWoundDashBoardAssessment(listPayLoad));
    }
  }, []);
    
    // For scrolling the page to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])   
  
  const [activePage, setActivePage] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [sortType, setSortType] = useState('')   

//Getting data from Clicked rows in Overview table through the API
const woundAssessmentData = useSelector((state) => state.woundreport.woundAssessmentData);

const columns = [

    {
        Header: ' Wound photo',
        accessor: 'woundImage',
        imageData: 'picture',
        restWidth: '120px',
        disableSortBy: true,

    },
    {
        Header: 'Patient\'s name',
        accessor: 'patientName',
        restWidth: '150px',
    },
    {
        Header: 'Wound',
        accessor: 'historyCause',
        restWidth: '350px'

    },
    {
        Header: 'Classification',
        accessor: 'classification',
        restWidth: '200px'

    },
    {
        Header: 'Assessed',
        accessor: 'lastAccessed',
        restWidth: '150px'

    },
    {
        Header: 'Admission date',
        accessor: 'admissionDate',
        restWidth: '250px'
    },
    {
        Header: 'Discharge date',
        accessor: 'dischargeDate',
        restWidth: '250px'
    },
    {
        Header: 'HPR Variable 1 - Worst Tissue Type',
        accessor: 'worstTissueType',
        restWidth: '265px'
    },
    {
        Header: 'HPR Variable 2 - Surface Area Value',
        accessor: 'surfaceAreaValue',
        restWidth: '265px'
    },
    {
        Header: 'HPR Variable 3 - Intact Value',
        accessor: 'intactValue',
        restWidth: '265px'
    },
];


  //redirect to patient-> assessments page 
  const getSelectedRowData = (data) => {
    if (data && !adminAccess) {
        history.push(`/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`);
    }
}
//   const getSelectedRowData = (data) => {
//     if (data) {
//       history.push(
//         `/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`
//       );
//     }
//   };

  const deleteDataList = () => {};

  const selectRowCount = () => {};  

  //sorting API call
  const handleSort = useCallback(
    (data) => {
      if (data && data.sortBy[0] && data.sortBy[0].id) {        
        const woundAssessmentPayload = { ...woundReportPayload, optionSelected: woundReportPayload?.optionSelected, woundProgress: woundReportPayload?.woundProgress }
        const sortingColumn = data.sortBy[0];
        const sortingType = sortingColumn
          ? sortingColumn.desc
            ? ":DESC"
            : ":ASC"
          : "";
        let sortParam = sortingColumn.id + sortingType;
        const listPayLoad = {};
        listPayLoad["url"] =  `${DASHBOARD_WOUNDASSESSMENT_OVERVIEW}/activewoundsnotresolved`;
        listPayLoad["method"] = "Post";
        listPayLoad["payload"] = woundAssessmentPayload;
        listPayLoad["queryParam"] = {
          offSet: isNaN(offSet)===true?0:offSet,
          limit: LIST_VIEW_LIMIT,
          sort: sortParam,
        };
        setSortType(sortParam)
        if (
          woundAssessmentPayload &&
          woundAssessmentPayload.facility &&
          woundAssessmentPayload.facility.length > 0
        ) {
          dispatch(getWoundDashBoardAssessment(listPayLoad));
        }
      }
    },
    [dispatch, woundReportPayload]
  );

  //active Page API Call
  const getActivePage = (data) => {
    setActivePage(data);
    const woundAssessmentPayload = { ...woundReportPayload, optionSelected: woundReportPayload?.optionSelected, woundProgress: woundReportPayload?.woundProgress }
    const listPayLoad = {};
    listPayLoad["url"] =  `${DASHBOARD_WOUNDASSESSMENT_OVERVIEW}/activewoundsnotresolved`;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundAssessmentPayload;
    listPayLoad["queryParam"] = {
      limit: LIST_VIEW_LIMIT,
      offSet:
        data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT < LIST_VIEW_LIMIT
          ? 0
          : data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT,
          sort: (sortType)?sortType:''
    };
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    if (
      woundAssessmentPayload &&
      woundAssessmentPayload.facility &&
      woundAssessmentPayload.facility.length > 0
    ) {
      dispatch(getWoundDashBoardAssessment(listPayLoad));
    }
  };

  return (
    <>
        <div className={`${styles.back_btn}`}>
            <Header 
                // heading="Admin Portal"
                // subHeading="Pending Assessments"  
                reportHeading={"Active wounds that are not resolved"} 
                backLink={'/woundReport'} 
                handleBack={() => history.goBack()} 
            />
        </div>
        {woundAssessmentData && woundAssessmentData?.woundAssessment && (
          <div className="col-12 px-0">
            <DataTable
              columns={columns}
              data={woundAssessmentData && woundAssessmentData?.woundAssessment}
              sorting={true}
              getSelectedRowData={getSelectedRowData}
              setSelectedRows={selectRowCount}
              onSort={handleSort}
              detailView={true}
              deleteDataList={deleteDataList}
              showFixedColumn={false}
              workListView={true}
              reportView={true}
              sortType={(sortType)?sortType:''}
            />
          </div>
        )}
        {Array.isArray(woundAssessmentData.woundAssessment) &&
          woundAssessmentData.woundAssessment.length > 1 && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={
                woundAssessmentData?.metadata
                  ? woundAssessmentData?.metadata?.limit
                  : 50
              }
              totalItemsCount={
                woundAssessmentData?.metadata
                  ? woundAssessmentData?.metadata?.total
                  : 0
              }
              getActivePage={getActivePage}
              reportView={true}
            />
        )}
    </>
  );
}//NOSONAR


ActiveWoundsNotResolved.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
};