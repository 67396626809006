import { subDays, subMonths } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../app/appSlice";
import { optionTypeAsync } from "../../../app/patientSlice";
import {
  getFaciltiestCustomerAsyn,
  setCustomerFacilities,
} from "../../../app/usageAnalyticsReportSlice";
import {
  getQualityAssuranceCountData,
  getQualityAssuranceOverViewData,
  getWoundDashBoardAssessment,
  getWoundDashBoardWoundcount,
  setQualityReportPayload,
  setQualityAssuranceOverViewData,
  setQualityAssuranceCountData,
  setQualityAssuranceAssessmentData,
  setWoundAssessmentData
} from "../../../app/woundReportSlice";
import Spreadsheet from "../../../static/images/ExportSpredsheet.svg";
import rightArrow from "../../../static/images/right_arrow.svg";
import { convertnewDateToDate, getFacilityLabel } from "../../../utils";
import {
  Button,
  CheckBox,
  DateTimePicker,
  Form,
  MultiSelect,
  Organizational,
  SelectBox,
  TextField,
} from "../../primary";
import { Tab as TabComponent } from "../../secondary";
import { Header } from "../../secondary/Header";
import { QualityReportData } from "./qualityReportData";
import style from "./qualityReportForm.module.scss";
import { DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT_OVERVIEW, LIST_VIEW_LIMIT } from '../../../constants';

export const QualityReportForm = ({ history }) => {
  //NOSONAR
  const [firstTime, setFirstTime] = useState("");
  const [tempPayload, setTempPayload] = useState("");
  const dispatch = useDispatch();
  const qualityReportPayload = useSelector(
    (state) => state.woundreport.qualityReportPayload
  );
  const userDetails = useSelector((state) => state.user);
  useEffect(() => {
    if (userDetails?.role !== "MedlineAdministrator"&&userDetails?.customerSettings?.ne1Wms === 0) {  
      history.push('/learnMore'); 
    }
    if (
      userDetails &&
      userDetails.customerSettings &&
      userDetails?.customerSettings?.ne1Wms === 0
    ) {
      if (
        userDetails?.role === "ExternalStandardUser" ||
        userDetails?.role === "ExternalAdministrator" ||
        userDetails.role === "ExternalNE1WoundExpert" ||
        userDetails.role === "ExternalNE1Review" ||
        userDetails.role === "ExternalNE1Assesment"
      ) {
        history.push("/learnMore");
      }
    }
  }, [userDetails]);
  useEffect(() => {
    setTempPayload("");
    dispatch(setQualityAssuranceCountData({}));
    dispatch(setQualityAssuranceOverViewData({}));
    dispatch(setQualityAssuranceAssessmentData({}));
    dispatch(setCustomerFacilities({}));
    dispatch(getFaciltiestCustomerAsyn(qualityReportPayload?.tempWoundCustomer
      ? qualityReportPayload.tempWoundCustomer
      :userDetails?.customerNumber));
    setFirstTime(true);
    setShowApplyState(false);
    dispatch(setQualityReportPayload({}));
    let wountCountPayload = {
      ...qualityReportPayload,
      section: "Image Capture",
      subsection: "Taken Timely",
    };
     dispatch(setQualityReportPayload(wountCountPayload));
  }, []);

  /* Use hook form */
  const {
    register,
    handleSubmit,
    control,
    errors,
    reset
  } = useForm();

  let dateRangeRef1 = useRef();
  let dateRangeRef2 = useRef();

  const [startDate, setStartDate] = useState(new Date());
  const [toDateRes, setToDateRes] = useState("");
  const [endDate, setEndDate] = useState();
  const [showApplyState, setShowApplyState] = useState(false);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [onselectOrgz, setOnselectOrg] = useState([]); 
  const [selectedDivOption, setSelectedDivOption] = useState(
    qualityReportPayload && qualityReportPayload.section
      ? qualityReportPayload.section
      : "Image Capture"
  );
  const [woundProgressValue, setWoundProgressValue] = useState(
    qualityReportPayload && qualityReportPayload.subsection
      ? qualityReportPayload.subsection
      : "Taken Timely"
  );
  const [selectedWoundValue, setSelectedWoundValue] = useState(qualityReportPayload?.woundType
    ? qualityReportPayload.woundType
    : []);
  const [errorMsg, setErrorMsg] = useState("");
  const [resetFacility, setResetFacility] = useState(false);

  const [submitState, setSubmitState] = useState(false);
  const [adminAccess, setAdminAccess] = useState(false);
  const [orgError, setOrgError] = useState(false);
  const woundTypeRef = useRef();
  const [tabIndex, setTabIndex] = useState(qualityReportPayload?.tabIndex? qualityReportPayload?.tabIndex:0);

  useEffect(() => {
    if (
      (userDetails && userDetails.role === "MedlineAdministrator") ||
      userDetails.role === "MedlineSupport"
    ) {
      setAdminAccess(true);
    }
  }, [userDetails]);

  const getFacilities = useSelector(
    (state) => state.usageAnalyticsReport.customerFacilities
  );
  let optionData = [];
  const soldToFacilities = getFacilities?.data?.facilities?.filter(
    (f) => f.accountGroup === "0001"
  );
  soldToFacilities &&
    soldToFacilities.forEach((soldToFacility) => {
      const shipToFacilities = getFacilities.data.facilities.filter(
        (f) =>
          f.accountGroup === "0002" &&
          f.soldToNumber === soldToFacility.facilityNumber
      );
      if (shipToFacilities.length === 0) {
        optionData.push({
          cat: getFacilityLabel(soldToFacility),
          key: getFacilityLabel(soldToFacility),
          facilityNumber: soldToFacility.facilityNumber,
          soldToFacility: "soldToFacility",
        });
      }
      shipToFacilities &&
        shipToFacilities.forEach((shipToFacility) => {
          optionData.push({
            cat: getFacilityLabel(soldToFacility),
            key: getFacilityLabel(shipToFacility),
            facilityNumber: shipToFacility.facilityNumber,
            shipToFacilities: "shipToFacilities",
          });
        });
    });

  useEffect(() => {
    let fac = "";
    if (getFacilities?.errorMessage) {
      fac = "Primary group code doesn’t match our record";
    }
    setErrorMsg(fac);
  }, [getFacilities]);

  const onSelectOrg = (selectedList) => {
    if (
      optionData &&
      selectedList &&
      optionData?.length !== selectedList?.length
    ) {
      setShowApplyState(true);
    }
    if (setSelectedFacilities) {
      setOrgError(false);
      let facilitykey = [];
      selectedList &&Array.isArray(selectedList)&&
        selectedList.map((item) => {//NOSONAR
          let facility = item.key.split("|")[0].trim();
          facilitykey = [...facilitykey, facility];
        })//NOSONAR
      setSelectedFacilities(facilitykey);
    }
    if(selectedList.length===0){
      setOnselectOrg([])
  }else{
    setOnselectOrg([...selectedList])
  }
  };

  const getCustomerNumber = (value) => {
    if (value === "") {
      setErrorMsg("Please enter primary group code");
    } else {
      // API to check wheather customer exists or not
      setShowApplyState(true);
      setOnselectOrg([]);
      dispatch(getFaciltiestCustomerAsyn(value));
      dispatch(setQualityReportPayload({...qualityReportPayload,onselectOrgz:[]}))
    }
  };

  useEffect(() => {
    dispatch(optionTypeAsync("insurancepayer"));
    dispatch(optionTypeAsync("woundType"));
  }, []);

  const insurancePayerData = useSelector(
    (state) => state.patient.insurancePayer
  );
  const woundTypeData = useSelector((state) => state.patient.woundType);

  const customFromDatePickerChange = (e) => {
    const fromDates = new Date(e).getTime();
    const toDates = new Date(
      document.getElementById("datePicker2").value
    ).getTime();
    setToDateRes(new Date(e));
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
      }
    }, 500);
    setShowApplyState(true);
  };
  const handleOnBlurFrom = ({ target: { value } }) => {
    const fromDates = new Date(value).getTime();
    const toDates = new Date(
      document.getElementById("datePicker2").value
    ).getTime();
    setToDateRes(new Date(value));
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
      }
    }, 500);
  };
  const handleOnBlurTO = ({ target: { value } }) => {
    const fromDates = new Date(
      document.getElementById("datePicker1").value
    ).getTime();
    const toDates = new Date(value).getTime();
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
        setToDateRes("");
      }
    }, 500);
  };
  const customToDatePickerChange = (e) => {
    setShowApplyState(true);
    const fromDates = new Date(
      document.getElementById("datePicker1").value
    ).getTime();
    const toDates = new Date(e).getTime();
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker1").value = "";
        setToDateRes("");
      }
    }, 500);
  };

  const handleWoundChange = (e) => {
    setShowApplyState(true);
    if (e && e?.length > 0) {
      let arr = [];
      e.map((data) => { //NOSONAR
        if (data.key !== "") {
          arr.push(data.key);
        }
      })//NOSONAR
      setSelectedWoundValue(arr);
    }
  };

  const handlePayorChange = (e) => {
    setShowApplyState(true);
  };
  const onClickCheckBox = (e) => {
    setShowApplyState(true);
  };

  // reset fields.
  const resetData = () => {
    setOnselectOrg([]);
    dispatch(setCustomerFacilities({}));
    dispatch(setQualityReportPayload({}));
    reset({ woundType: "", payorType: "", isDischargedPatient: "" });
    woundTypeRef.current.resetSelectedValues([]);
    setSelectedWoundValue("");
    setShowApplyState(true);
    setSubmitState(false);
    setResetFacility(true);
    setSelectedDivOption("Image Capture");
    setWoundProgressValue("Taken Timely");
    dispatch(getFaciltiestCustomerAsyn(userDetails?.customerNumber));
    setTimeout(() => {
      setFirstTime(true);
    }, 500);
  };

  const optionSelected = (e) => {
    setTabIndex(0)
    dispatch(setQualityReportPayload({...qualityReportPayload,tabIndex:0}))
    setSelectedDivOption(e);
    if (e === "Image Capture") {
      setWoundProgressValue("Taken Timely");
      let qualitytPayload = { ...qualityReportPayload, section: e,subsection:'Taken Timely'}
            dispatch(setQualityReportPayload(qualitytPayload))
      let wountCountPayload = {
        ...tempPayload,
        section: e,
        subsection: "Taken Timely",
      };
      dispatch(getQualityAssuranceCountData(wountCountPayload));
    } else if (e === "Assessments") {
      setWoundProgressValue("Performed Timely");
      let qualitytPayload = { ...qualityReportPayload, section: e,subsection:'Performed Timely'}
             dispatch(setQualityReportPayload(qualitytPayload))
      let wountCountPayload = {
        ...tempPayload,
        section: e,
        subsection: "Performed Timely",
      };
      dispatch(getQualityAssuranceCountData(wountCountPayload));
    } else if (e === "Measurements") {
      setWoundProgressValue("No Surface Area");
      let qualitytPayload = { ...qualityReportPayload, section: e,subsection:'No Surface Area' }
             dispatch(setQualityReportPayload(qualitytPayload))
      let wountCountPayload = {
        ...tempPayload,
        section: e,
        subsection: "No Surface Area",
      };
      dispatch(getQualityAssuranceCountData(wountCountPayload));
    } else if (e === "Treatments") {
      setWoundProgressValue("Dressing Appearance Not Set");
      let qualitytPayload = { ...qualityReportPayload, section: e,subsection:'Dressing Appearance Not Set' }
            dispatch(setQualityReportPayload(qualitytPayload))
      let wountCountPayload = {
        ...tempPayload,
        section: e,
        subsection: "Dressing Appearance Not Set",
      };
      dispatch(getQualityAssuranceCountData(wountCountPayload));
    }
  };

  const imageCaptureSelectedTab = (e) => {
    setTabIndex(e)
    dispatch(setQualityReportPayload({...qualityReportPayload,tabIndex:e}))
    if (e == 0) {
      setWoundProgressValue("Taken Timely");
    } else if (e == 1) {
      setWoundProgressValue("Taken Late");
    } else if (e == 2) {
      setWoundProgressValue("Missing Sticker");
    } else if (e == 3) {
      setWoundProgressValue("Auto Traced");
    } else if (e == 4) {
      setWoundProgressValue("Manually Traced");
    }
  };
  const assessmentSelectedTab = (e) => {
    setTabIndex(e)
    dispatch(setQualityReportPayload({...qualityReportPayload,tabIndex:e}))
    if (e == 0) {
      setWoundProgressValue("Performed Timely");
    } else if (e == 1) {
      setWoundProgressValue("Performed Late");
    } else if (e == 2) {
      setWoundProgressValue("Last Assessed Over 7 Days Ago");
    } else if (e == 3) {
      setWoundProgressValue("Tissue Types Not Set");
    } else if (e == 4) {
      setWoundProgressValue("Touch View Details Not Set");
    } else if (e == 5) {
      setWoundProgressValue("Classification Not Set");
    } else if (e == 6) {
      setWoundProgressValue("Wound Progress Not Set");
    }
  };
  const measurementSelectedTab = (e) => {
    setTabIndex(e)
    dispatch(setQualityReportPayload({...qualityReportPayload,tabIndex:e}))
    if (e == 0) {
      setWoundProgressValue("No Surface Area");
    } else if (e == 1) {
      setWoundProgressValue("No Length");
    } else if (e == 2) {
      setWoundProgressValue("No Width");
    } else if (e == 3) {
      setWoundProgressValue("No Depth");
    } else if (e == 4) {
      setWoundProgressValue("No Undermining");
    } else if (e == 5) {
      setWoundProgressValue("No Tunneling");
    }
  };
  const treatmentSelectedTab = (e) => {
    setTabIndex(e)
    dispatch(setQualityReportPayload({...qualityReportPayload,tabIndex:e}))
    if (e == 0) {
      setWoundProgressValue("Dressing Appearance Not Set");
    } else if (e == 1) {
      setWoundProgressValue("Primary Dressing Not Set");
    } else if (e == 2) {
      setWoundProgressValue("Secondary Dressing Not Set");
    } else if (e == 3) {
      setWoundProgressValue("Dressing Change Frequency Not Set");
    }
  };

  const pageOnloadAPI = () => {
    let facilitykey = [];
    optionData &&
      Array.isArray(optionData) &&
      optionData.map((item) => {//NOSONAR
        let facility = item.key.split("|")[0].trim();
        facilitykey = [...facilitykey, facility];
      })//NOSONAR
    const payload = {
      fromDate: convertnewDateToDate(
        new Date(document.getElementById("datePicker1").value)
      ),
      toDate: convertnewDateToDate(
        new Date(document.getElementById("datePicker2").value)
      ),
      facility: facilitykey,
      woundType: "",
      payor: "",
      isDischargedPatient: 0,
      section: "Image Capture",
      subsection: "Taken Timely",
    };
    if (optionData.length > 0) {
      if (
        qualityReportPayload &&
        qualityReportPayload?.facility &&
        qualityReportPayload?.facility.length !== 0
      ) {
        dispatch(getQualityAssuranceOverViewData(qualityReportPayload));
        setTempPayload(qualityReportPayload);
        let wountCountPayload = {
          ...qualityReportPayload,
          section: qualityReportPayload.section,
        };
        dispatch(getQualityAssuranceCountData(wountCountPayload));
        setShowApplyState(true);
      } else {
        dispatch(getQualityAssuranceOverViewData(payload));
        setTempPayload(payload);
        let wountCountPayload = { ...payload, section: selectedDivOption };
        dispatch(getQualityAssuranceCountData(wountCountPayload));
        dispatch(setQualityReportPayload({ ...wountCountPayload, section: "Image Capture",subsection:"Taken Timely",onselectOrgz:onselectOrgz}))
      }
      if (document.getElementById("datePicker1").value) {
        setToDateRes(new Date(document.getElementById("datePicker1").value));
      }
    }
  };

  const onClickOverview = (value) => {
    let getClickPath = value
    let facilitykey = [];
    optionData &&
      Array.isArray(optionData) &&
      optionData.map((item) => {//NOSONAR
        let facility = item.key.split("|")[0].trim();
        facilitykey = [...facilitykey, facility];
      })//NOSONAR
    const payload = {
      fromDate: convertnewDateToDate(
        new Date(document.getElementById("datePicker1").value)
      ),
      toDate: convertnewDateToDate(
        new Date(document.getElementById("datePicker2").value)
      ),
      facility: facilitykey,
      woundType: "",
      payor: "",
      isDischargedPatient: 0,
      section: "Image Capture",
      subsection: "Taken Timely",
    };     

    if (optionData.length > 0) {
      let listPayLoad = {};
      listPayLoad['url'] = `${DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT_OVERVIEW}/${getClickPath}`;
      listPayLoad['method'] = 'Post'
      listPayLoad['payload'] = payload
      listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT }
      if(payload&&payload.facility&&payload.facility.length>0){
          dispatch(getWoundDashBoardAssessment(listPayLoad))
      }
    }
  };

  const onClickPendingPhoto = () => {
    dispatch(setWoundAssessmentData({}));
    onClickOverview("pendingphotos")   
    dispatch(setLoading(false))
      history.push({
          pathname: 'qualityReport/pendingphotos',
          state: true
      });  
  }

  const onClickPendingAssessments = () => {
    dispatch(setWoundAssessmentData({}));
    onClickOverview("pendingassessments")  
    dispatch(setLoading(false))
            history.push({
            pathname: 'qualityReport/pendingassessments',
            state: true
        });
  }

  const onClickPendingReviews = () => {
    dispatch(setWoundAssessmentData({}));
    onClickOverview("pendingreviews")
    dispatch(setLoading(true))
            history.push({
            pathname: 'qualityReport/pendingreviews',
            state: true
        });
  }

  const onClickCompletedAssessments = () => {
    dispatch(setWoundAssessmentData({}));
    onClickOverview("completedassessments")
    dispatch(setLoading(true))
            history.push({
            pathname: 'qualityReport/completedassessments',
            state: true
        });
  }


  useEffect(() => {
    if (
      firstTime &&
      getFacilities?.data?.facilities.length > 0 &&
      document.getElementById("datePicker1") &&
      document.getElementById("datePicker2")
    ) {
      setTimeout(() => {
        setFirstTime(false);
        pageOnloadAPI();
      }, 100);
    }
  }, [firstTime, getFacilities]);

  const onSubmit = (data) => {
    setErrorMsg("");
    if (selectedFacilities.length === 0) {
      setOrgError(true);
    
      if (selectedFacilities.length >0) {
      dispatch(getQualityAssuranceOverViewData());
      dispatch(getWoundDashBoardWoundcount());}
    } else {
      setOrgError(false);
    }
    setSubmitState(true);
    let payload = {
      fromDate: convertnewDateToDate(data.datePicker1),
      toDate: convertnewDateToDate(data.datePicker2),
      facility: selectedFacilities,
      woundType: selectedWoundValue,
      payor: data.payorType,
      isDischargedPatient:
        data.isDischargedPatient === "Include Discharged Patients" ? 1 : 0,
        onselectOrgz:onselectOrgz
    };
    const fromDates = new Date(
      document.getElementById("datePicker1").value
    ).getTime();
    const toDates = new Date(
      document.getElementById("datePicker2").value
    ).getTime();
    setToDateRes(new Date(document.getElementById("datePicker1").value));
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById("datePicker2").value = "";
      } else {
        if (selectedFacilities.length >0) {
        
        dispatch(getQualityAssuranceOverViewData(payload));
        // let wountCountPayload = { ...payload, section: selectedDivOption}
        let wountCountPayload = {
          ...payload,
          section: selectedDivOption,
          subsection: woundProgressValue,
          onselectOrgz:onselectOrgz
        };
        //setSelectedDivOption("Image Capture")
        //setWoundProgressValue
        setTempPayload(wountCountPayload);
        dispatch(getQualityAssuranceCountData(wountCountPayload));

        let tempWoundPayload = wountCountPayload;
        if (document.getElementById("customerNumber").value) {
          tempWoundPayload = {
            ...tempWoundPayload,
            tempWoundCustomer: document.getElementById("customerNumber").value,
          };
        }
         dispatch(setQualityReportPayload(tempWoundPayload))
      }
    }
    }, 10);
  };

  const overViewData = useSelector(
    (state) => state.woundreport.qualityAssuranceOverViewData
  );

  const woundCountData = useSelector(
    (state) => state.woundreport.qualityAssuranceCountData
  );

  const qualityPdfPage = () => {
    dispatch(setLoading(true));
    history.push("/qualityReport/pdfdownload");
  };

  const imageCaptureTabList = [
    {
      name: (
        <>
          {" "}
          Taken Timely <span>{woundCountData?.takentimely}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          {" "}
          Taken Late <span>{woundCountData?.takenlate}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          {" "}
          Missing Sticker <span>{woundCountData?.missingsticker}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          {" "}
          Auto Traced <span>{woundCountData?.autotraced}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Manually Traced <span> {woundCountData?.manuallytraced}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
  ];
  const assessmentTabList = [
    {
      name: (
        <>
          Performed Timely <span>{woundCountData?.performedtimely}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Performed Late <span>{woundCountData?.performedlate}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Last Assessed Over 7 Days Ago{" "}
          <span>{woundCountData?.lastassessedover7daysago}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Tissue Types Not Set <span>{woundCountData?.tissuetypesnotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Touch &amp; View Details Not Set{" "}
          <span>{woundCountData?.touchviewdetailsnotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Classification Not Set{" "}
          <span>{woundCountData?.classificationnotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Wound Progress Not Set{" "}
          <span>{woundCountData?.woundprogressnotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
  ];
  const measurementTabList = [
    {
      name: (
        <>
          No Surface Area <span>{woundCountData?.nosurfacearea}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          No Length <span>{woundCountData?.nolength}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          No Width <span>{woundCountData?.nowidth}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          No Depth <span>{woundCountData?.nodepth}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          No Undermining <span>{woundCountData?.noundermining}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          No Tunneling <span>{woundCountData?.notunneling}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
  ];
  const treatmentTabList = [
    {
      name: (
        <>
          Dressing Appearance Not Set{" "}
          <span>{woundCountData?.dressingappearancenotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Primary Dressing Not Set{" "}
          <span>{woundCountData?.primarydressingnotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Secondary Dressing Not Set{" "}
          <span>{woundCountData?.secondarydressingnotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
    {
      name: (
        <>
          Dressing Change Frequency Not Set{" "}
          <span>{woundCountData?.dressingchangefrequencynotset}</span>
        </>
      ),
      content: (
        <QualityReportData
          woundProgressValue={woundProgressValue}
          tempPayload={tempPayload}
          selectedDivOption={selectedDivOption}
        />
      ),
      className: style.tab_none,
    },
  ];

  return (
    <>
      <div className="right_block">
        <Header reportHeading={"Quality Assurance"} />
        <div className={`col ${style.wound_dashboard} mt-0`}>
          <Form className={style.wound_form} onSubmit={handleSubmit(onSubmit)}>
            <div className={`col-8 ${style.col_spec}`}>
              <label className={`col-5 ${style.label_spec}`}>Date Range:</label>
              <DateTimePicker
                id={"datePicker1"}
                name={"datePicker1"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 ${style.col_spec}`,
                }}
                selected={startDate}
                customOnChange={customFromDatePickerChange}
                defaultValue={
                  qualityReportPayload && qualityReportPayload.fromDate
                    ? new Date(qualityReportPayload.fromDate)
                    // : subDays(new Date(startDate), 30)
                    : subMonths(new Date(startDate), 6)
                }
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef1}
                errors={errors}
                maxDate={new Date()}
                onBlur={handleOnBlurFrom}
              />
              <DateTimePicker
                id={"datePicker2"}
                name={"datePicker2"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 pr-0 ${style.col_spec}`,
                }}
                defaultValue={
                  qualityReportPayload && qualityReportPayload.toDate
                    ? new Date(qualityReportPayload.toDate)
                    : new Date()
                }
                customOnChange={customToDatePickerChange}
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef2}
                minDate={toDateRes ? toDateRes : ""}
                maxDate={new Date()}
                errors={errors}
                onBlur={handleOnBlurTO}
              />
            </div>
            <div className={`col-4 ${style.col_spec}`}></div>
            {adminAccess && (
              <>
                <TextField
                  id={"customerNumber"}
                  name={"customerNumber"}
                  label={"Primary Group Code:"}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    labelClass: `col-5 col-form-label ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                    inputClassName: `${style.wound_input}  customerNum`,
                  }}
                  type="text"
                  errors={errors}
                  validator={register}
                  required={true}
                  customError={errorMsg ? errorMsg : ""}
                  defaultValue={
                    qualityReportPayload &&
                    qualityReportPayload.tempWoundCustomer
                      ? qualityReportPayload.tempWoundCustomer
                      : ""
                  }
                />
                <span
                  className={`col-3 col-form-label ${style.form_span} getorg `}
                  onClick={(e) =>
                    getCustomerNumber(
                      document.getElementById("customerNumber").value
                    )
                  }
                >
                  Search{" "}
                  <img
                    src={rightArrow}
                    alt=""
                    className={`${style.img_left}`}
                  />
                </span>
              </>
            )}
            {optionData &&
              Array.isArray(optionData) &&
              optionData.length === 0 && (
                <Organizational
                  id={"facility"}
                  name={"facility"}
                  label={`Facility/Location:`}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    inputClassName: style.wound_select,
                    labelClassName: `col-5 ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                  }}
                  optionValue={optionData}
                  groupBy="cat"
                  displayValue="key"
                  onSelect={(val) => onSelectOrg(val)}
                  selectAll={true}
                  resetDefault={resetFacility}
                  reportView={true}
                  customError={orgError}
                  required={true}
                  defaultValue={Array.isArray(onselectOrgz)&&onselectOrgz.length>0?onselectOrgz:optionData}
                />
              )}
            {optionData &&
              Array.isArray(optionData) &&
              optionData.length !== 0 && (
                <Organizational
                  id={"facility"}
                  name={"facility"}
                  label={`Facility/Location:`}
                  classes={{
                    column: `col-8 ${style.col_spec}`,
                    inputClassName: style.wound_select,
                    labelClassName: `col-5 ${style.label_spec}`,
                    inputColumn: `col-7 p-0`,
                  }}
                  optionValue={optionData}
                  groupBy="cat"
                  displayValue="key"
                  defaultValue={qualityReportPayload &&
                    qualityReportPayload.onselectOrgz
                      ? qualityReportPayload.onselectOrgz
                      :Array.isArray(onselectOrgz)&&onselectOrgz.length>0?onselectOrgz:optionData}
                  onSelect={(val) => onSelectOrg(val)}
                  selectAll={true}
                  resetDefault={resetFacility}
                  reportView={true}
                  customError={orgError}
                  required={true}
                />
              )}

            <div className={`col-4 ${style.col_spec}`}></div>
            <MultiSelect
              id={"woundType"}
              name={"woundType"}
              label={"Wound Types:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0 ${style.select_wrapper}`,
              }}
              optionValue={woundTypeData}
              validator={(e) => {
                register({ required: true });
                woundTypeRef.current = e;
              }}
              placeholder="Select"
              showCheckbox={true}
              displayValue="value"
              onSelect={handleWoundChange}
              onRemove={handleWoundChange}
              labelSubText={"Select all that apply"}
              defaultValue={
                qualityReportPayload && qualityReportPayload.woundType
                  ? qualityReportPayload.woundType
                  : []
              }
            />

            <div className={`col-4 ${style.col_spec}`}></div>
            <SelectBox
              id={"payorType"}
              name={"payorType"}
              label={"Payor Type:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0 ${style.select_payortype}`,
              }}
              optionValue={insurancePayerData}
              validator={register}
              placeholder="Select"
              errors={errors}
              onChange={(e) => handlePayorChange(e.currentTarget.value)}
              defaultValue={
                qualityReportPayload && qualityReportPayload.payor
                  ? qualityReportPayload.payor
                  : ""
              }
            />

            <div className={`col-4 ${style.col_spec}`}></div>

            <div className={`col-8 ${style.col_spec} p-0`}>
              <div className="col-6 offset-5 p-0 mb-0">
                <CheckBox
                  classes={{ labelClassName: style.custom_control_label }}
                  label={"Include Discharged Patients"}
                  type={"checkbox"}
                  name={"isDischargedPatient"}
                  id={"checkBoxId"}
                  validator={register}
                  onClick={(e) => onClickCheckBox(e.currentTarget.value)}
                  woundReport={true}
                  defaultChecked={
                    qualityReportPayload &&
                    qualityReportPayload.isDischargedPatient === 1
                      ? true
                      : ""
                  }
                />
              </div>
            </div>
            <div className={`col-4 ${style.col_spec}`}></div>
            {showApplyState && (
              <div className={`col-8 ${style.col_spec}`}>
                <label className={`col-5 ${style.label_spec}`}></label>
                <Button
                  classes={{
                    className: `btn ${style.btn_custom} ${style.btn_small} mr-4`,
                  }}
                  type="submit"
                >
                  Apply
                </Button>
                <Button
                  classes={{
                    className: `btn ${style.btn_custom} ${style.btn_small_cancel} `,
                  }}
                  onClick={resetData}
                  type={"button"}
                >
                  Reset
                </Button>
              </div>
            )}
          </Form>

          {overViewData != "" && woundCountData != "" ? (
            <>
              <div className={style.overview_blk}>
                <div className={style.overview_top}>
                  <p className={style.overview_title}>Overview</p>
                  <span className={style.down_spread} onClick={qualityPdfPage}>
                    <img src={Spreadsheet} alt="" />
                    <label> Download as PDF </label>
                  </span>
                </div>
                <div className={style.overview_btm}>
                  <p>
                    <label className={style.pend_photos}>Pending Photos</label>
                    {/* <span>{overViewData?.pendingPhotos}</span> */}
                    <span className={`${style.commonCusrsor}`} onClick={()=>onClickPendingPhoto()}>{overViewData?.pendingPhotos}</span>
                  </p>
                  <p>
                    <label className={style.pend_assessments}>
                      Pending Assessments
                    </label>
                    {/* <span>{overViewData?.pendingAssessments}</span> */}
                    <span className={`${style.commonCusrsor}`} onClick={()=>onClickPendingAssessments()}>{overViewData?.pendingAssessments}</span>
                  </p>
                  <p>
                    <label className={style.pend_review}>Pending Reviews</label>
                    {/* <span>{overViewData?.pendingReviews}</span> */}
                    <span className={`${style.commonCusrsor}`} onClick={()=>onClickPendingReviews()}>{overViewData?.pendingReviews}</span>
                  </p>
                  <p>
                    <label className={style.comp_assess}>
                      Completed Assessments
                    </label>
                    {/* <span>{overViewData?.completedAssessments}</span> */}
                    <span className={`${style.commonCusrsor}`} onClick={()=>onClickCompletedAssessments()}>{overViewData?.completedAssessments}</span>
                  </p>
                </div>
                <div className={style.quality_score}>
                  <div
                    className={`crd_opt_1 ${style.quality_percent} ${
                      selectedDivOption === "Image Capture" ? style.active : ""
                    }`}
                    onClick={() => optionSelected("Image Capture")}
                  >
                    <label className={`${style.quality_top} ${style.qu_image}`}>
                      Image Capture
                    </label>
                  </div>
                  <div
                    className={`crd_opt_2 ${style.quality_percent} ${
                      selectedDivOption === "Assessments" ? style.active : ""
                    }`}
                    onClick={() => optionSelected("Assessments")}
                  >
                    <label
                      className={`${style.quality_top} ${style.qu_assses}`}
                    >
                      Assessments
                    </label>
                  </div>
                  <div
                    className={`crd_opt_3 ${style.quality_percent} ${
                      selectedDivOption === "Measurements" ? style.active : ""
                    }`}
                    onClick={() => optionSelected("Measurements")}
                  >
                    <label
                      className={`${style.quality_top} ${style.qu_measure}`}
                    >
                      Measurements
                    </label>
                  </div>
                  <div
                    className={`crd_opt_4 ${style.quality_percent} ${
                      selectedDivOption === "Treatments" ? style.active : ""
                    }`}
                    onClick={() => optionSelected("Treatments")}
                  >
                    <label className={`${style.quality_top} ${style.qu_treat}`}>
                      Treatments
                    </label>
                  </div>
                </div>
              </div>
              <div>
                {selectedDivOption && selectedDivOption === "Image Capture" && (
                  <TabComponent
                    onSelect={imageCaptureSelectedTab}
                    defaultIndex={tabIndex?tabIndex:0}
                    tabList={imageCaptureTabList}
                    woundReportView={true}
                    workListView={true}
                  />
                )}
                {selectedDivOption && selectedDivOption === "Assessments" && (
                  <TabComponent
                    onSelect={assessmentSelectedTab}
                    defaultIndex={tabIndex?tabIndex:0}
                    tabList={assessmentTabList}
                    woundReportView={true}
                    workListView={true}
                  />
                )}
                {selectedDivOption && selectedDivOption === "Measurements" && (
                  <TabComponent
                    onSelect={measurementSelectedTab}
                    defaultIndex={tabIndex?tabIndex:0}
                    tabList={measurementTabList}
                    woundReportView={true}
                    workListView={true}
                  />
                )}
                {selectedDivOption && selectedDivOption === "Treatments" && (
                  <TabComponent
                    onSelect={treatmentSelectedTab}
                    defaultIndex={tabIndex?tabIndex:0}
                    tabList={treatmentTabList}
                    woundReportView={true}
                    workListView={true}
                  />
                )}
              </div>
            </>
          ) : (
            <div className={style.no_matching_result}>
              Oops there are no matching results, please update your filter
            </div>
          )}
        </div>
      </div>
    </>
  );
}//NOSONAR
